var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c(
      "div",
      [
        _c("div", { staticClass: "profile_v3", attrs: { id: "profile" } }, [
          _c(
            "div",
            { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
            [
              _c("a", {
                staticClass: "g_close icon-gClose",
                on: { click: _vm.closeClick },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "title pageTitle",
                domProps: { textContent: _vm._s(_vm.LS.col_member_center) },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "profileContent" },
            [
              _vm.sessionMid == 0 && _vm.manageMode
                ? [
                    _c(_vm.manageComponent, {
                      tag: "component",
                      attrs: { "module-id": _vm.moduleId },
                    }),
                  ]
                : _vm.sessionMid != 0
                ? [
                    _c(_vm.visitorComponent, {
                      tag: "component",
                      attrs: { "module-id": _vm.moduleId },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _vm.memberBulletinOpen
          ? [
              _c(
                "div",
                {
                  staticClass: "J_cusBack modifyProfile",
                  attrs: { id: "mBulletinConten" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "loginHeader webHeaderBg profileLoginHeader",
                    },
                    [
                      _c("span", {
                        staticClass: "g_close icon-gClose",
                        on: { click: _vm.mBulletinContenClick },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "title pageTitle" }, [
                        _vm._v(_vm._s(_vm.bulletinTitle)),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.bulletinContent == null ||
                  _vm.bulletinContent == "<p><br /></p>" ||
                  _vm.bulletinContent == "<p></p>"
                    ? [
                        _c("div", { staticClass: "hasNoMsg" }, [
                          _c("img", {
                            staticClass: "hasNoMsgIcon",
                            attrs: { src: _vm.noMsgImgPath, alt: "" },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "bulletin_no_cotent_v3",
                          domProps: {
                            textContent: _vm._s(_vm.LS.memberNoMsgV3),
                          },
                        }),
                      ]
                    : [
                        _c("div", {
                          staticClass: "bulletin_cotent_v3",
                          domProps: { innerHTML: _vm._s(_vm.bulletinContent) },
                        }),
                      ],
                ],
                2
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.isOpenKnowPayment
          ? [
              _c("div", { attrs: { id: "knowPayList" } }, [
                _c(
                  "div",
                  { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
                  [
                    _c("span", {
                      staticClass: "g_close icon-gClose",
                      on: { click: _vm.knowPayClick },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "title pageTitle" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "profileContent profile_content_v3" },
                  [
                    !_vm.isOnlyFinishedOrder
                      ? _c(
                          "div",
                          { staticClass: "know_pay_list_kind" },
                          _vm._l(_vm.orderNavs, function (orderNav, index) {
                            return _c(
                              "div",
                              {
                                staticClass:
                                  "know_pay_list_kind_item g_color g_main_color_v3",
                                class: {
                                  know_pay_list_kind_item_select:
                                    index === _vm.selectOrderNavIndex,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeOrderNav(index)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(orderNav))]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", {
                      staticStyle: {
                        height: "0.4266rem",
                        "background-color": "#f4f4f4",
                      },
                    }),
                    _vm._v(" "),
                    _vm.curKnowList.length
                      ? _c(
                          "ul",
                          { staticClass: "productList" },
                          [
                            _vm._l(_vm.curKnowList, function (item) {
                              return [
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: item.jumpUrl,
                                        onclick: _vm.getUrlArgsOnclickStr(item),
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "know_pay_list_item" },
                                        [
                                          _c("div", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "know_pay_list_item_header",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.LS.mallStlOrderCode
                                                    ) + _vm._s(item.code)
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "know_pay_list_item_pay_status g_color g_main_color_v3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getPayStatusText(
                                                          false,
                                                          item.payStatus
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "know_pay_list_item_main",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    position: "relative",
                                                    border: "solid 1px #eee",
                                                    "margin-right": "0.7893rem",
                                                  },
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "know_pay_list_item_product_img",
                                                    style:
                                                      "background-image: url(" +
                                                      item.picPath +
                                                      ")",
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "know_pay_list_item_product_label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    " +
                                                          _vm._s(
                                                            item.labelText
                                                          ) +
                                                          "\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "know_pay_list_item_product_info",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "know_pay_list_item_product_name",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.productName)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "know_pay_list_item_product_buy_time",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.LS.purchaseTime
                                                        ) +
                                                          ": " +
                                                          _vm._s(
                                                            _vm.formatDate(
                                                              item.payTime
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "know_pay_list_item_product_price g_color g_main_color_v3",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "¥" +
                                                          _vm._s(
                                                            item.price.toFixed(
                                                              2
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.getShowAftersale(item)
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "know_pay_list_item_product_action",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.knowItemProductAction(
                                                        item,
                                                        $event
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.refundStatus === 0
                                                        ? "申请退款"
                                                        : "售后进度"
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: {
                                    height: "0.4266rem",
                                    "background-color": "#f4f4f4",
                                  },
                                }),
                              ]
                            }),
                          ],
                          2
                        )
                      : _c("div", { staticClass: "empty_tips" }, [
                          _c("div", { staticClass: "img" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.LS.no_know_pd_list)),
                          ]),
                        ]),
                  ]
                ),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.isOpenMyForm
          ? [
              _c("div", { attrs: { id: "formList" } }, [
                _c(
                  "div",
                  { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
                  [
                    _c("span", {
                      staticClass: "g_close icon-gClose",
                      on: { click: _vm.formListClick },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "title pageTitle" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "profileContent profile_content_v3" },
                  [_c("ul", { staticClass: "area" })]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { form_detail_preview: _vm.formPreviewState },
                  attrs: { id: "formDetail" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "loginHeader webHeaderBg profileLoginHeader",
                    },
                    [
                      _c("span", {
                        staticClass: "g_close icon-gClose",
                        on: { click: _vm.formDetailClick },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "title pageTitle" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isEditState,
                        expression: "!isEditState",
                      },
                    ],
                    staticClass: "profileContent profile_content_v3",
                  }),
                  _vm._v(" "),
                  _vm.isEditState
                    ? _c(
                        "div",
                        [
                          _c("form-detail-edit", {
                            on: { "submit-success": _vm.onEditSuccess },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.openMemberModifySubmit
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isEditState,
                              expression: "!isEditState",
                            },
                          ],
                          staticClass: "form_edit_button",
                          on: { click: _vm.setEditState },
                        },
                        [_vm._v(_vm._s(_vm.LS.edit_form_result))]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "J_cusBack viewMyReward",
            attrs: { id: "viewMyReward" },
          },
          [
            _c(
              "div",
              { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
              [
                _c("span", {
                  staticClass: "g_close icon-gClose",
                  on: { click: _vm.viewMyRewardClick },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "title pageTitle" }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "view-reward-content" }, [
              _c("ul", { staticClass: "reward-list" }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "J_cusBack modifyProfile",
            attrs: { id: "modifyPersInfo" },
          },
          [
            _c(
              "div",
              { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
              [
                _c("span", {
                  staticClass: "g_close icon-gClose",
                  on: { click: _vm.modifyPersInfoClick },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "title pageTitle" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "profileContent profile_content_v3" },
              [
                _c("div", { staticClass: "member_basic_info_v3" }, [
                  _c(
                    "div",
                    { staticClass: "member_msg_head_pic_v3 icon-pnameIcon" },
                    [
                      _c("img", {
                        staticClass:
                          "memberMsgHeadPicV3 memberHeadPicV3 J_memberHeadPicV3",
                        attrs: { id: "memberHeadPicV3", alt: "" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "member_name_and_level" }, [
                    _c("span", {
                      staticClass: "click_change_icon",
                      domProps: { textContent: _vm._s(_vm.LS.changeHeadPic) },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "g_globalLine_wrap" }, [
                  _c("div", { staticClass: "g_globalLine" }, [
                    _c("div", { staticClass: "g_globalLine" }, [
                      _c("span", {
                        staticClass:
                          "icon-acctIcon profile_v3_msg_icon g_mainColor",
                      }),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "acct g_input",
                        class: _vm.acctInputClass,
                        attrs: {
                          type: "text",
                          maxlength: "20",
                          disabled: _vm.acctIconDisable,
                        },
                        domProps: { value: _vm.memberAcct },
                      }),
                    ]),
                    _vm._v(" "),
                    _vm.canResetAcct
                      ? _c("div", {
                          staticClass: "acctDes",
                          domProps: {
                            textContent: _vm._s(
                              _vm.LS.memberProfile_onlyResetOnce
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _vm._l(_vm.displayList, function (item) {
                  return _c(
                    "div",
                    { key: item.fieldKey, staticClass: "g_globalLine_wrap" },
                    [
                      item.isMobile && (!_vm.signByMobile || !_vm.isCn)
                        ? _c("div", { attrs: { id: "editMobile" } }, [
                            _c(
                              "div",
                              { staticClass: "g_globalLine mobileLine" },
                              [
                                _c("div", {
                                  staticClass: "mobileCtVal",
                                  domProps: {
                                    textContent: _vm._s(_vm.mobileCtCode),
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "mobileCtBorder" }),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    staticClass:
                                      "g_input itemInput infoItem select_v3 icon-select",
                                    class: item.mustItemClass,
                                    attrs: { id: "mobileCt", name: "mobileCt" },
                                    on: {
                                      click: _vm.mobileCtClick,
                                      blur: _vm.mobileCtBlur,
                                      change: _vm.mobileCtChange,
                                    },
                                  },
                                  _vm._l(_vm.mobileCtNameList, function (ct) {
                                    return _c("option", {
                                      key: ct.ctName,
                                      domProps: {
                                        value: ct.ctName,
                                        textContent: _vm._s(
                                          ct.ctShowName + ct.ctCode
                                        ),
                                      },
                                    })
                                  }),
                                  0
                                ),
                                _vm._v(" "),
                                _c("span", {
                                  class: item.customPropName,
                                  domProps: { textContent: _vm._s(item.name) },
                                }),
                                _c("input", {
                                  staticClass: "g_input itemInput infoItem",
                                  class: item.mustItemClass,
                                  attrs: {
                                    id: "mobile",
                                    name: item.fieldKey,
                                    type: "text",
                                    maxlength: "11",
                                    placeholder: item.propPlaceholder,
                                    propTip: item.propTip,
                                  },
                                  domProps: { value: _vm.preMobile },
                                }),
                                _c("span", { class: item.itemIcon }),
                              ]
                            ),
                          ])
                        : [
                            _c(
                              "div",
                              {
                                staticClass: "g_globalLine",
                                class: _vm.fileClass(item),
                              },
                              [
                                item.isMobile
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "editMobileBtn",
                                        attrs: { id: "editMobileBtn" },
                                        on: { click: _vm.editMobileBtnClick },
                                      },
                                      [_vm._v("修改")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.pt == 0
                                  ? [
                                      JSON.parse(item.pce).mc == 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "g_input",
                                              staticStyle: {
                                                display: "inline-block",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.values || "-"))]
                                          )
                                        : _c("input", {
                                            staticClass:
                                              "g_input itemInput infoItem",
                                            class: _vm.singleLineClass(item),
                                            style: item.disStyle,
                                            attrs: {
                                              propTip: item.propTip,
                                              type: "text",
                                              name: item.fieldKey,
                                              maxlength: "50",
                                              placeholder: item.propPlaceholder,
                                              disabled: item.disable,
                                            },
                                            domProps: { value: item.values },
                                          }),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass: "profile_v3_msg_icon",
                                        class: item.itemIcon,
                                      }),
                                    ]
                                  : item.pt == 1
                                  ? [
                                      JSON.parse(item.pce).mc == 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "g_input",
                                              staticStyle: {
                                                display: "inline-block",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.values || "-"))]
                                          )
                                        : [
                                            _c("div", {
                                              staticClass:
                                                "mobileCtIcon otherSettingIcon icon-downward",
                                            }),
                                            _vm._v(" "),
                                            _c("input", {
                                              staticClass:
                                                "g_input selectCover",
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "select",
                                              {
                                                staticClass:
                                                  "g_input itemInput infoItem select_v3 icon-select",
                                                class: item.mustItemClass,
                                                attrs: {
                                                  id: item.fieldKey,
                                                  name: item.fieldKey,
                                                  propTip: item.propTip,
                                                },
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { attrs: { value: "" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.must
                                                          ? _vm.LS
                                                              .mobi_member_PleaseInput +
                                                              " " +
                                                              item.name +
                                                              _vm.LS
                                                                .mobi_member_Must
                                                          : _vm.LS
                                                              .mobi_member_PleaseInput +
                                                              " " +
                                                              item.name
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  item.propOptions,
                                                  function (option) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: option,
                                                        domProps: {
                                                          value: option,
                                                          selected:
                                                            option ==
                                                            item.values,
                                                        },
                                                      },
                                                      [_vm._v(_vm._s(option))]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass: "profile_v3_msg_icon",
                                        class: item.itemIcon,
                                      }),
                                    ]
                                  : item.pt == 2
                                  ? [
                                      item.customPropName != ""
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "profile_file_label",
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "member_file_contentwrap profile_file_contentwrap",
                                          attrs: {
                                            id:
                                              "member_file_contentwrap-" +
                                              item.id,
                                          },
                                        },
                                        [
                                          _c("input", {
                                            staticClass:
                                              "infoItem member_file_input",
                                            class: item.mustItemClass,
                                            attrs: {
                                              id: item.fieldKey,
                                              name: item.fieldKey,
                                              propTip: item.propTip,
                                              "data-name": item.id,
                                            },
                                            domProps: { value: item.values },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "member_file_btn",
                                              class:
                                                _vm.hideAddFileIconClass(item),
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "member_file_btnupload uploadify-button",
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.addFileText
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "member_file_content",
                                              class:
                                                _vm.showFileDetailClass(item),
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "member_file_meta",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "member_file_name",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getName(item)
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "member_file_size",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getSize(item)
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "member_file_icon",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "member_file_close",
                                                      class:
                                                        _vm.hideDeleteIconClass(
                                                          item
                                                        ),
                                                    },
                                                    [
                                                      _c("use", {
                                                        attrs: {
                                                          "xlink:href":
                                                            "#jzm-v25",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      ref: "nofllow",
                                                      refInFor: true,
                                                      staticClass:
                                                        "member_file_preview_link",
                                                      attrs: {
                                                        href: "javascript:void(0)",
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          staticClass:
                                                            "member_file_preview",
                                                        },
                                                        [
                                                          _c("use", {
                                                            attrs: {
                                                              "xlink:href":
                                                                "#jzm-vicon_preview",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      ref: "nofllow",
                                                      refInFor: true,
                                                      staticClass:
                                                        "member_file_download_link",
                                                      attrs: {
                                                        href: _vm.getDownloadUrl(
                                                          item
                                                        ),
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          staticClass:
                                                            "member_file_download",
                                                        },
                                                        [
                                                          _c("use", {
                                                            attrs: {
                                                              "xlink:href":
                                                                "#jzm-v16",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "profile_file_tips" },
                                        [_vm._v(_vm._s(_vm.getFileTips(item)))]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                item.customPropName != "" && item.pt !== 2
                                  ? _c("span", { class: item.customPropName }, [
                                      _vm._v(_vm._s(item.name)),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                    ],
                    2
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "sendBtnWrap" }, [
                  _c("input", {
                    staticClass:
                      "fpw_btn g_button g_main_bgColor_v3 sendIcon profileSubmit",
                    attrs: { type: "button", value: _vm.LS.mobi_avatarSaveV3 },
                    on: { click: _vm.setPersonalInfoClick },
                  }),
                ]),
              ],
              2
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "loginErrorLine" }, [
          _c("div", { staticClass: "errorTips" }),
        ]),
        _vm._v(" "),
        _vm.signByMobile
          ? [
              _c(
                "div",
                {
                  staticClass: "J_cusBack mobilePanel modifyPersInfo",
                  staticStyle: { display: "none" },
                  attrs: { id: "editMobilePanel" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "loginHeader webHeaderBg profileLoginHeader",
                    },
                    [
                      _c("span", {
                        staticClass: "g_close icon-gClose",
                        on: { click: _vm.mobileCodeClick },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "title pageTitle" }, [
                        _vm._v("修改手机号"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "profileContent" }, [
                    _c("div", { staticClass: "m_pointBoxContainer" }, [
                      _c("div", { attrs: { id: "inputMobile" } }, [
                        _c("div", { staticClass: "defMobi" }, [
                          _vm._v("当前手机号：" + _vm._s(_vm.preMobile)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "f_editArea" }, [
                          _c(
                            "select",
                            {
                              staticClass:
                                "itemInput infoItem select_v3 icon-select mustItem mobileCtNew",
                              attrs: { id: "mobileCt" },
                              on: {
                                click: _vm.inputMobileClick,
                                blur: _vm.inputMobileBlur,
                                change: _vm.inputMobileChange,
                              },
                            },
                            _vm._l(_vm.mobileCtNameList, function (ct) {
                              return _c("option", {
                                key: ct.ctName,
                                domProps: {
                                  value: ct.ctName,
                                  selected: ct.ctCode == _vm.mobileCtCode,
                                  textContent: _vm._s(ct.ctCode),
                                },
                              })
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "g_input editInput mobileCtInput",
                            attrs: {
                              id: "mobile",
                              maxlength: "11",
                              placeholder: "请输入新的手机号码",
                            },
                            domProps: { value: _vm.preMobile },
                            on: { input: _vm.mobileInput },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "cleanBtn",
                              style: _vm.cleanStyle,
                              attrs: { id: "cleanBtn" },
                              on: { click: _vm.cleanBtnClick },
                            },
                            [_vm._v("x")]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { display: "none" },
                          attrs: { id: "mobileValidate" },
                        },
                        [
                          _c("div", { staticClass: "validateCodeMsg" }, [
                            _vm._v("请输入"),
                            _c("span", { attrs: { id: "newMobile" } }),
                            _vm._v("收到的短信验证码"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "f_editArea" }, [
                            _c("div", { staticClass: "validateCode" }, [
                              _vm._v("验证码"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              staticClass:
                                "g_input editInput validateCodeInput",
                              attrs: {
                                id: "validateCodeInput",
                                maxlength: "8",
                                placeholder: "请输入验证码",
                              },
                              on: { input: _vm.validateCodeInput },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "cleanBtn cleanBtnValidateCode",
                                staticStyle: { display: "none" },
                                attrs: { id: "cleanValidateCodeInput" },
                                on: { click: _vm.cleanValidateCodeInputClick },
                              },
                              [_vm._v("x")]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "sendValidateCode sendValidateCode1",
                              attrs: {
                                id: "messageAuthCodeClick",
                                title: _vm.LS.mobi_getMobileCode,
                              },
                              domProps: {
                                textContent: _vm._s(_vm.LS.mobi_getMobileCode),
                              },
                              on: { click: _vm.messageAuthCodeClick },
                            }),
                            _vm._v(" "),
                            _c("div", {
                              staticStyle: { display: "none" },
                              attrs: { id: "showCaptcha" },
                            }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "f_saveBtn mobileCtBtn g_main_bgColor_v3 un-change",
                          attrs: { id: "saveMobileBtn" },
                          on: { click: _vm.saveMobileBtnClick },
                        },
                        [_vm._v("确定")]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "J_cusBack modifyProfile",
            attrs: { id: "modifyPsw" },
          },
          [
            _c(
              "div",
              { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
              [
                _c("span", {
                  staticClass: "g_close icon-gClose",
                  on: { click: _vm.modifyPswClick },
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "title pageTitle",
                  domProps: {
                    textContent: _vm._s(_vm.LS.mobi_membert_ResetPassword),
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "profileContent" }, [
              _vm.needOldPwd
                ? _c("div", { staticClass: "g_globalLine_wrap" }, [
                    _c("div", { staticClass: "g_globalLine" }, [
                      _c("input", {
                        staticClass: "g_input itemInput mustItem",
                        attrs: {
                          id: "oldPsw",
                          type: "password",
                          autocomplete: "off",
                          maxlength: "20",
                          placeholder: _vm.LS.mobi_member_OldPasswordV3,
                        },
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "icon-cnOldPsw profile_v3_msg_icon",
                      }),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "g_globalLine_wrap" }, [
                _c("div", { staticClass: "g_globalLine" }, [
                  _c("input", {
                    staticClass:
                      "g_input itemInput mustItem profile_v3_msg_icon",
                    attrs: {
                      id: "newPsw",
                      type: "password",
                      autocomplete: "off",
                      maxlength: "20",
                      placeholder: _vm.LS.mobi_memberProfile_password,
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "icon-cnNewPsw profile_v3_msg_icon",
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "g_globalLine_wrap" }, [
                _c("div", { staticClass: "g_globalLine" }, [
                  _c("input", {
                    staticClass:
                      "g_input itemInput mustItem profile_v3_msg_icon",
                    attrs: {
                      id: "confirmPsw",
                      type: "password",
                      autocomplete: "off",
                      maxlength: "20",
                      placeholder: _vm.LS.mobi_memberSignup_retypePasswordV3,
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "icon-cnNewPsw profile_v3_msg_icon",
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "g_globalLine_wrap" }, [
                _c("div", { staticClass: "g_globalLine" }, [
                  _c("input", {
                    staticClass: "g_button profileSubmit g_main_bgColor_v3",
                    class: { sendIcon: !_vm.manageMode },
                    attrs: {
                      type: "button",
                      value: _vm.LS.mobi_avatarSaveV3,
                      disabled: "",
                    },
                    on: { click: _vm.memberProfileSubmitClick },
                  }),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "J_cusBack modifyProfile",
            attrs: { id: "modifyIntegral" },
          },
          [
            _c(
              "div",
              { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
              [
                _c("span", {
                  staticClass: "g_close icon-gClose",
                  on: { click: _vm.modifyIntegralClick },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "title pageTitle" }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "profileContent profile_content_v3" }, [
              _c("div", { staticClass: "itgPanel" }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "J_cusBack modifyProfile",
            attrs: { id: "modifyIntegralRule" },
          },
          [
            _c(
              "div",
              { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
              [
                _c("span", {
                  staticClass: "g_close icon-gClose",
                  on: { click: _vm.modifyIntegralRuleCloseClick },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "title pageTitle" }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "profileContent profile_content_v3" }, [
              _c("div", { staticClass: "itgPanelRule" }),
              _vm._v(" "),
              _c("div", { domProps: { innerHTML: _vm._s(_vm.richContent) } }),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.isCrownVer
          ? [
              _vm.openCollection
                ? _c(
                    "div",
                    {
                      staticClass: "J_cusBack modifyProfile",
                      attrs: { id: "modifyCollection" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "loginHeader webHeaderBg profileLoginHeader",
                        },
                        [
                          _c("span", {
                            staticClass: "g_close icon-gClose",
                            on: { click: _vm.openCollectionClick },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "title pageTitle" }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "profileContent profile_content_v3" },
                        [
                          _c("img", {
                            staticStyle: { display: "none" },
                            attrs: { alt: "" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "collectionList" }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.openCoupon
                ? _c(
                    "div",
                    {
                      staticClass: "J_cusBack modifyProfile",
                      attrs: { id: "modifyCoupon" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "loginHeader webHeaderBg profileLoginHeader",
                        },
                        [
                          _c("span", {
                            staticClass: "g_close icon-gClose",
                            on: { click: _vm.modifyCouponClick },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "title pageTitle" }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "couponListPanel" }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isOpenCutPrice && !_vm.manageMode
                ? _c(
                    "div",
                    {
                      staticClass: "J_cusBack modifyProfile",
                      attrs: { id: "modifyCutPrice" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "loginHeader webHeaderBg profileLoginHeader",
                        },
                        [
                          _c("span", {
                            staticClass: "g_close icon-gClose",
                            on: { click: _vm.modifyCutPriceCloseClick },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "title pageTitle" }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "cutPriceListPanel" }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isOpenGroupBuy && !_vm.manageMode
                ? _c(
                    "div",
                    {
                      staticClass: "J_cusBack modifyProfile",
                      attrs: { id: "modifyGroupBuy" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "loginHeader webHeaderBg profileLoginHeader",
                        },
                        [
                          _c("span", {
                            staticClass: "g_close icon-gClose",
                            on: { click: _vm.modifyGroupBuyCloseClick },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "title pageTitle" }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "groupBuyListPanel" }),
                    ]
                  )
                : _vm._e(),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "J_cusBack modifyProfile",
            attrs: { id: "editMemberHeadPic" },
          },
          [
            _c("div", { staticClass: "loginHeader profileLoginHeader" }, [
              _c("span", {
                staticClass: "g_close icon-gClose",
                on: { click: _vm.editMemberHeadPicCloseClick },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "picArea", attrs: { id: "picArea" } }, [
              _c("img", { attrs: { id: "headPic", _src: _vm.imgPath } }),
              _vm._v(" "),
              _c("div", { staticClass: "cover cover1" }),
              _vm._v(" "),
              _c("div", { staticClass: "cover cover2" }),
              _vm._v(" "),
              _c("img", {
                staticClass: "coverImg",
                staticStyle: { opacity: "0.5" },
                attrs: {
                  id: "coverImg",
                  src: _vm.resImageRoot + "/image/member/layer.png",
                },
                on: { dragstart: false },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "cover cover4" }),
              _vm._v(" "),
              _c("div", { staticClass: "cover cover5" }),
              _vm._v(" "),
              _c("div", { staticClass: "coverRing" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "coverBox",
                  attrs: { id: "coverBox", ondragstart: "return false;" },
                },
                [
                  _c("div", {
                    staticClass: "dragPoint",
                    attrs: { id: "dragLeftTop" },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "dragPoint",
                    attrs: { id: "dragRightTop" },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "dragPoint",
                    attrs: { id: "dragLeftBot" },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "dragPoint",
                    attrs: { id: "dragRightBot" },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "dragPoint",
                    attrs: { id: "dragTopCenter" },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "dragPoint",
                    attrs: { id: "dragRightCenter" },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "dragPoint",
                    attrs: { id: "dragLeftCenter" },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "dragPoint",
                    attrs: { id: "dragBotCenter" },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "editHeadPicFooter editHeadPicFooterV3" },
              [
                _c("div", { staticClass: "editBtn modifyBtn" }, [
                  _vm._v(_vm._s(_vm.LS.mobi_avatarReplace)),
                  _c("span", {
                    staticClass: "fileUploadBtn",
                    staticStyle: { width: "5rem" },
                    attrs: { id: "file_upload" },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "editBtn saveBtn",
                    attrs: { oldimgid: _vm.oldImgId, mid: _vm.sessionMid },
                  },
                  [_vm._v(_vm._s(_vm.LS.mobi_avatarSaveV3))]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "J_cusBack modifyProfile",
            staticStyle: { display: "none" },
            attrs: { id: "bindOtherAcct" },
          },
          [
            _c(
              "div",
              { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
              [
                _c("span", {
                  staticClass: "g_close icon-gClose",
                  attrs: { id: "bindAcctBack" },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "title pageTitle" }, [
                  _vm._v(_vm._s(_vm.LS.mobi_member_OtherLgnBindAcct)),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "loginContent" }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "J_cusBack modifyProfile",
            staticStyle: { display: "none" },
            attrs: { id: "mSignUp" },
          },
          [
            _c(
              "div",
              { staticClass: "loginHeader webHeaderBg profileLoginHeader" },
              [
                _c("span", {
                  staticClass: "g_close icon-gClose",
                  attrs: { id: "signUpBack" },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "title pageTitle" }, [
                  _vm._v(_vm._s(_vm.LS.col_member_signup)),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "loginContent" }),
            _vm._v(" "),
            _c("div", {
              staticClass: "checkEmail",
              attrs: { id: "checkEmail" },
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "emailAddr",
                staticStyle: { display: "none" },
                attrs: { id: "emailAddr", target: "_blank" },
              },
              [_vm._v("地址")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "J_cusBack",
            staticStyle: { display: "none" },
            attrs: { id: "requestRefund" },
          },
          [
            _c("div", { staticClass: "loginHeader profileLoginHeader" }, [
              _c("span", {
                staticClass: "g_close icon-gClose",
                on: { click: _vm.requestRefundCloseClick },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "title pageTitle" }, [
                _vm._v("申请退款"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "request_refund",
                  staticStyle: {
                    display: "flex",
                    padding: "0.4266rem 0.64rem 0.64rem",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "know_pay_list_item_main",
                      staticStyle: { border: "none" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "relative",
                            border: "solid 1px #eee",
                            "margin-right": "0.7893rem",
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "know_pay_list_item_product_img",
                            style:
                              "background-image: url(" +
                              _vm.curKnow.picPath +
                              ")",
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "know_pay_list_item_product_label" },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.curKnow.labelText) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "know_pay_list_item_product_info" },
                        [
                          _c(
                            "span",
                            { staticClass: "know_pay_list_item_product_name" },
                            [_vm._v(_vm._s(_vm.curKnow.productName))]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "know_pay_list_item_product_buy_time",
                            },
                            [
                              _vm._v(
                                "购买时间：" +
                                  _vm._s(_vm.formatDate(_vm.curKnow.payTime))
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "know_pay_list_item_product_price g_color g_main_color_v3",
                            },
                            [_vm._v("¥" + _vm._s(_vm.curKnow.price.toFixed(2)))]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticStyle: {
                  height: "0.4266rem",
                  "background-color": "#f4f4f4",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { padding: "0.4266rem 0.64rem 0.64rem" } },
                [
                  _c("div", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.refundReason,
                          expression: "refundReason",
                        },
                      ],
                      staticClass: "withdrawal_reason",
                      attrs: {
                        maxlength: "200",
                        placeholder: "请输入退款原因",
                      },
                      domProps: { value: _vm.refundReason },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.refundReason = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", [
                      _c("div", {
                        ref: "uploadBtn",
                        staticClass: "upload_btn",
                        staticStyle: { display: "none" },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.tmpFileList.length < 3,
                                  expression: "tmpFileList.length < 3",
                                },
                              ],
                              staticStyle: {
                                width: "4.0533rem",
                                height: "4.0533rem",
                                "border-radius": "0.1706rem",
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center",
                                "background-color": "#f3f3f3",
                                "margin-right": "0.6826rem",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.uploadClick.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticStyle: {
                                    width: "1.28rem",
                                    height: "0.9386rem",
                                  },
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 32 32",
                                  },
                                },
                                [
                                  _c("circle", {
                                    attrs: {
                                      cx: "16",
                                      cy: "17",
                                      r: "4",
                                      fill: "#ccc",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d: "M26,7H23L21,5H11L9,7H6a4,4,0,0,0-4,4V23a4,4,0,0,0,4,4H26a4,4,0,0,0,4-4V11A4,4,0,0,0,26,7ZM6.5,13A1.5,1.5,0,1,1,8,11.5,1.5,1.5,0,0,1,6.5,13ZM16,23a6,6,0,1,1,6-6A6,6,0,0,1,16,23Z",
                                      fill: "#ccc",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.tmpFileList, function (img, index) {
                            return _c(
                              "div",
                              {
                                staticClass: "refund_photo",
                                staticStyle: {
                                  width: "4.0533rem",
                                  height: "4.0533rem",
                                  "margin-right": "0.6826rem",
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "refund_photo_item",
                                  attrs: { src: img.picPath },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "refund_photo_imgPreviewerMaskLayer",
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "refund_photo_imgViewIcon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.previewImg(img)
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "svg",
                                      {
                                        staticClass:
                                          "refund_photo_imgRemoveIcon",
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 32 32",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delRefundPhoto(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm4.71,17.29a1,1,0,0,1,0,1.42,1,1,0,0,1-1.42,0L16,17.41l-3.29,3.3a1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.42L14.59,16l-3.3-3.29a1,1,0,0,1,1.42-1.42L16,14.59l3.29-3.3a1,1,0,0,1,1.42,1.42L17.41,16Z",
                                            fill: "rgb(255, 84, 20)",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", {
                        ref: "uploadBtn2",
                        staticStyle: { display: "none" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "refund_upload_split" }, [
                        _c(
                          "svg",
                          {
                            staticStyle: {
                              width: "0.576rem",
                              height: "0.576rem",
                              "margin-right": "0.192rem",
                            },
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 32 32",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,23a2,2,0,1,1,2-2A2,2,0,0,1,16,25Zm2.59-14.75L17.18,18a1.2,1.2,0,0,1-2.36,0l-1.41-7.77a2.63,2.63,0,1,1,5.18,0Z",
                                fill: "#ccc",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "0.512rem",
                              color: "#999",
                              padding: "0.512rem 0",
                            },
                          },
                          [
                            _vm._v(
                              "文字不多于200字，图片不多于3张，每个" +
                                _vm._s(_vm.uploadSize) +
                                "M以内"
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#333",
                          "font-size": "0.64rem",
                          margin: "0.512rem 0",
                          display: "inline-block",
                        },
                      },
                      [_vm._v("联系方式")]
                    ),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.contactInformation,
                          expression: "contactInformation",
                        },
                      ],
                      staticClass: "request_refund_phone",
                      attrs: {
                        maxlength: "200",
                        placeholder: "方便商家联系您",
                      },
                      domProps: { value: _vm.contactInformation },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.contactInformation = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "g_main_bgColor_v3 g_oldThemeBgColor",
                  staticStyle: {
                    width: "14.72rem",
                    height: "1.92rem",
                    "border-radius": "0.1706rem",
                    "align-items": "center",
                    "justify-content": "center",
                    display: "flex",
                    "font-size": "0.64rem",
                    color: "#fff",
                    margin: "auto",
                  },
                  on: { click: _vm.requestRefund },
                },
                [_vm._v("\n                    提交申请\n                ")]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "J_cusBack",
            staticStyle: { display: "none" },
            attrs: { id: "aftersaleRecord" },
          },
          [
            _c("div", { staticClass: "loginHeader profileLoginHeader" }, [
              _c("span", {
                staticClass: "g_close icon-gClose",
                on: { click: _vm.aftersaleRecordCloseClick },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "title pageTitle" }, [
                _vm._v("售后进度"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "g_bgColor g_main_bgColor_v3",
                  staticStyle: {
                    "box-sizing": "border-box",
                    padding: "0.64rem",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        height: "100%",
                        padding: "0.64rem",
                        "border-radius": "0.1706rem",
                        "box-sizing": "border-box",
                        "background-color": "#fff",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "margin-bottom": "0.64rem",
                          },
                        },
                        [
                          _vm.aftersaleRecordStatus === 3
                            ? _c(
                                "svg",
                                {
                                  staticClass: "g_color g_main_color_v3",
                                  staticStyle: {
                                    width: "0.8533rem",
                                    height: "0.8533rem",
                                  },
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 32 32",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M12,21a9,9,0,0,1,12-8.48V8a4,4,0,0,0-4-4H8A4,4,0,0,0,4,8V24a4,4,0,0,0,4,4h7.35A9,9,0,0,1,12,21ZM8,7H20a1,1,0,0,1,0,2H8A1,1,0,0,1,8,7Zm3,7H8a1,1,0,0,1,0-2h3a1,1,0,0,1,0,2Z",
                                      fill: "currentColor",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d: "M21,14a7,7,0,1,0,7,7A7,7,0,0,0,21,14Zm3.71,5.71-4,4a1,1,0,0,1-1.42,0l-2-2a1,1,0,0,1,1.42-1.42L20,21.59l3.29-3.3a1,1,0,0,1,1.42,1.42Z",
                                      fill: "currentColor",
                                    },
                                  }),
                                ]
                              )
                            : _vm.aftersaleRecordStatus === 5
                            ? _c(
                                "svg",
                                {
                                  staticClass: "g_color g_main_color_v3",
                                  staticStyle: {
                                    width: "0.8533rem",
                                    height: "0.8533rem",
                                  },
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 32 32",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M12,21a9,9,0,0,1,12-8.48V8a4,4,0,0,0-4-4H8A4,4,0,0,0,4,8V24a4,4,0,0,0,4,4h7.35A9,9,0,0,1,12,21ZM8,7H20a1,1,0,0,1,0,2H8A1,1,0,0,1,8,7Zm3,7H8a1,1,0,0,1,0-2h3a1,1,0,0,1,0,2Z",
                                      fill: "currentColor",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d: "M21,14a7,7,0,1,0,7,7A7,7,0,0,0,21,14Zm2.71,8.29a1,1,0,0,1,0,1.42,1,1,0,0,1-1.42,0L21,22.41l-1.29,1.3a1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.42L19.59,21l-1.3-1.29a1,1,0,0,1,1.42-1.42L21,19.59l1.29-1.3a1,1,0,0,1,1.42,1.42L22.41,21Z",
                                      fill: "currentColor",
                                    },
                                  }),
                                ]
                              )
                            : _vm.aftersaleRecordStatus === 2 ||
                              _vm.aftersaleRecordStatus === 1
                            ? _c(
                                "svg",
                                {
                                  staticClass: "g_color g_main_color_v3",
                                  staticStyle: {
                                    width: "0.8533rem",
                                    height: "0.8533rem",
                                  },
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 32 32",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M12,21a9,9,0,0,1,12-8.48V8a4,4,0,0,0-4-4H8A4,4,0,0,0,4,8V24a4,4,0,0,0,4,4h7.35A9,9,0,0,1,12,21ZM8,7H20a1,1,0,0,1,0,2H8A1,1,0,0,1,8,7Zm3,7H8a1,1,0,0,1,0-2h3a1,1,0,0,1,0,2Z",
                                      fill: "currentColor",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d: "M21,14a7,7,0,1,0,7,7A7,7,0,0,0,21,14Zm4,9H21a1,1,0,0,1-1-1V17a1,1,0,0,1,2,0v4h3a1,1,0,0,1,0,2Z",
                                      fill: "currentColor",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "0.64rem",
                                color: "#333",
                                "margin-left": "0.3413rem",
                              },
                            },
                            [_vm._v(_vm._s(_vm.statusText))]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "0.4693rem",
                            color: "#666",
                          },
                          style:
                            _vm.aftersaleRecordStatus === 1
                              ? "white-space: pre-line; line-height: 25px"
                              : "",
                        },
                        [_vm._v(_vm._s(_vm.statusTips))]
                      ),
                      _vm._v(" "),
                      _vm.aftersaleRecordStatus === 3
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "0.4693rem",
                                color: "#666",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.formatDate(
                                    _vm.latestAftersaleRecord.replyTime
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("div", {
                    staticStyle: {
                      height: "0.4266rem",
                      "background-color": "#f4f4f4",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "1.7066rem",
                        display: "flex",
                        "align-items": "center",
                        "border-bottom": "1px solid #eee",
                        "justify-content": "space-between",
                        padding: "0 0.64rem",
                        "box-sizing": "border-box",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "0.64rem",
                            color: "#333",
                          },
                        },
                        [_vm._v("售后记录")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "look_all_aftersale_record",
                          staticStyle: {
                            "font-size": "0.5973rem",
                            color: "#999",
                            display: "flex",
                            "align-items": "center",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.previewAllAftersaleRecord(
                                _vm.latestAftersaleRecord
                              )
                            },
                          },
                        },
                        [
                          _vm._v("查看更多"),
                          _c(
                            "svg",
                            {
                              staticStyle: {
                                width: "0.7rem",
                                height: "0.7rem",
                              },
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 32 32",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M11,28a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L20.59,16,10.29,5.71a1,1,0,0,1,1.42-1.42l11,11a1,1,0,0,1,0,1.42l-11,11A1,1,0,0,1,11,28Z",
                                  fill: "#999",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("aftersale-record", {
                    attrs: {
                      price: _vm.getProductPrice(
                        _vm.latestAftersaleRecord.orderId
                      ),
                      "show-aciton": true,
                      "latest-aftersale-record": _vm.latestAftersaleRecord,
                      "wxpay-auto-refund": _vm.wxpayAutoRefund,
                    },
                    on: { "modify-request": _vm.modifyRequest },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "J_cusBack",
            staticStyle: { display: "none" },
            attrs: { id: "aftersaleRecordAll" },
          },
          [
            _c("div", { staticClass: "loginHeader profileLoginHeader" }, [
              _c("span", {
                staticClass: "g_close icon-gClose",
                on: { click: _vm.aftersaleRecordAllCloseClick },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "title pageTitle" }, [
                _vm._v("售后记录"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              _vm._l(_vm.aftersaleRecords, function (aftersaleRecord) {
                return _c("aftersale-record", {
                  key: aftersaleRecord.id,
                  attrs: {
                    price: _vm.getProductPrice(aftersaleRecord.orderId),
                    "show-aciton": false,
                    "latest-aftersale-record": aftersaleRecord,
                    "wxpay-auto-refund": _vm.wxpayAutoRefund,
                  },
                })
              }),
              1
            ),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }