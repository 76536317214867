//取消安全模式
export const disableSafeMode = function () {
    let url = Fai.top.location.href || '';
    const location = window.location;
    if (location.href.indexOf('_safeMode=true') < 0) {
        jm.cookie('_mobiSafeMode', null, { path: '/', domain: location.host });
        location.reload();
    } else {
        location.href = url.replace('_safeMode=true', '_safeMode=false');
    }
};
