var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "module-frame",
    { attrs: { "module-id": _vm.moduleId } },
    [
      _vm.isEmptyProductInfo || (_vm.notAdded && !_vm._manageMode)
        ? [
            _c("div", { staticClass: "productNotAdded productNotAdded-icon" }),
            _vm._v(" "),
            _c("div", { staticClass: "productNotAdded-tip" }, [
              _vm._v(_vm._s(_vm.productNotAdded)),
            ]),
          ]
        : [
            _c(
              "div",
              {
                staticClass: "productDetail_v1",
                attrs: { id: _vm.proDetailPanelId },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "proDetailDiv",
                    attrs: { id: _vm.proDetailDivId },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "proImgSwipe",
                        attrs: { id: "productImageSwipe" },
                      },
                      [
                        _vm.openKnowPayment
                          ? [
                              _vm.isVideoPayProduct
                                ? _c("div", { staticClass: "knowPayProduct" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "proDetailImg J_img_lazyload",
                                        style: _vm.proDetailImgStyle,
                                        attrs: { "src-original": _vm.picPath },
                                      },
                                      [
                                        _vm.isFinPay
                                          ? [
                                              _c("div", {
                                                staticClass:
                                                  "videoPlay faisco-icons-",
                                                on: {
                                                  click:
                                                    _vm.checkKnowPayVideoHandler,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "none",
                                                  },
                                                  attrs: {
                                                    id: "knowproductVideoMask",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mobiProductVideoDialog productKnowPayVideo",
                                                    },
                                                    [
                                                      _c("video", {
                                                        staticClass:
                                                          "mobiVideo",
                                                        style:
                                                          _vm.knowPayVideoStyle,
                                                        attrs: {
                                                          id: "knowPayVideo",
                                                          controls: "",
                                                          "webkit-playsinline":
                                                            "true",
                                                          playsinline: "true",
                                                          controlsList:
                                                            "nodownload",
                                                          preload: "auto",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "videoQuit",
                                                      on: {
                                                        click:
                                                          _vm.productKnowPayVideoQuit,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          staticClass:
                                                            "videoQuit-close",
                                                        },
                                                        [
                                                          _c("use", {
                                                            attrs: {
                                                              href: "#jzm-vicon_close",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : [
                                              _vm.finalOpener
                                                ? [
                                                    _c("div", {
                                                      staticClass:
                                                        "videoPlay faisco-icons-",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.checkKnowPayVideoHandler(
                                                            _vm.productId,
                                                            _vm.finalOpener
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "videoTryTipContainer",
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "videoTryBg",
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "videoTryTip",
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.tryTips
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "none",
                                                        },
                                                        attrs: {
                                                          id: "knowproductVideoMask",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mobiProductVideoDialog productKnowPayVideo",
                                                          },
                                                          [
                                                            _c("video", {
                                                              staticClass:
                                                                "mobiVideo",
                                                              style:
                                                                _vm.knowPayVideoStyle,
                                                              attrs: {
                                                                id: "knowPayVideo",
                                                                controls: "",
                                                                "webkit-playsinline":
                                                                  "true",
                                                                playsinline:
                                                                  "true",
                                                                controlsList:
                                                                  "nodownload",
                                                                preload: "auto",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "videoQuit",
                                                            on: {
                                                              click:
                                                                _vm.productKnowPayVideoQuit,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "svg",
                                                              {
                                                                staticClass:
                                                                  "videoQuit-close",
                                                              },
                                                              [
                                                                _c("use", {
                                                                  attrs: {
                                                                    href: "#jzm-vicon_close",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : [
                                                    _c("div", {
                                                      staticClass:
                                                        "videoPlay faisco-icons-",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.checkKnowPayVideoHandler(
                                                            _vm.productId
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "none",
                                                        },
                                                        attrs: {
                                                          id: "knowproductVideoMask",
                                                        },
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "mobiProductVideoDialog productKnowPayVideo",
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "videoQuit",
                                                            on: {
                                                              click:
                                                                _vm.productKnowPayVideoQuit,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "svg",
                                                              {
                                                                staticClass:
                                                                  "videoQuit-close",
                                                              },
                                                              [
                                                                _c("use", {
                                                                  attrs: {
                                                                    href: "#jzm-vicon_close",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                            ],
                                      ],
                                      2
                                    ),
                                  ])
                                : _vm.isAudioProduct
                                ? _c(
                                    "div",
                                    { attrs: { id: "productAudioPlayer" } },
                                    [
                                      _c("product-audio-player", {
                                        attrs: {
                                          "pic-path": _vm.audioPicPath,
                                          "istry-open": _vm.istryOpen,
                                          "is-v1": _vm.isV1,
                                          "is-default-color-style":
                                            _vm.isDefaultColorStyle,
                                          "follow-color": _vm.followColor,
                                          "is-use-custom-color":
                                            _vm.isUseCustomColor,
                                          "is-fin-pay": _vm.isFinPay,
                                          "audio-try-min": _vm.audioTryMin,
                                          "img-zoom-class": _vm.imgZoomClass,
                                          "product-id": _vm.productId,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isVideoPayProduct && !_vm.isAudioProduct
                          ? _c(
                              "div",
                              { staticClass: "proDetailImgBox" },
                              [
                                !_vm.isEmptyPicPathList
                                  ? _vm._l(_vm.picPathList, function (pic) {
                                      return _c(
                                        "div",
                                        { staticClass: "swipImgBox" },
                                        [
                                          _c("span", {
                                            staticClass: "imageMiddleSpan",
                                          }),
                                          _vm._v(" "),
                                          _c("img", {
                                            staticClass: "J_img_lazyload",
                                            attrs: {
                                              vwidth: pic.width,
                                              vheight: pic.height,
                                              src: _vm.loadingPath,
                                              "src-original": pic.newPath,
                                            },
                                          }),
                                        ]
                                      )
                                    })
                                  : _c("div", [
                                      _c("img", {
                                        staticClass: "proDetailImg",
                                        attrs: { src: _vm.defaultPath },
                                      }),
                                    ]),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.picPathList.length > 1 && !_vm.openKnowPayment
                          ? _c(
                              "div",
                              {
                                staticClass: "productBullet",
                                attrs: { id: "productBullet" },
                              },
                              [
                                _c(
                                  "ul",
                                  {
                                    staticClass: "bullets",
                                    attrs: { id: "bullets" },
                                  },
                                  _vm._l(_vm.bullets, function (item, index) {
                                    return index < 5
                                      ? _c("li", { class: { on: index === 1 } })
                                      : _vm._e()
                                  }),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "paramCollection" },
                      [
                        _c("h1", {
                          staticClass: "g_text paramName",
                          domProps: { innerHTML: _vm._s(_vm.name) },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.showPropList, function (propInfo) {
                          return [
                            propInfo.value
                              ? _c(
                                  "p",
                                  { staticClass: "g_text productParam" },
                                  [
                                    _vm.showPar
                                      ? _c("span", [
                                          _vm._v(_vm._s(propInfo.name) + "："),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    propInfo.type === 11
                                      ? _c(
                                          "span",
                                          { staticClass: "mallPrice" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.choiceCurrencyVal)
                                            ),
                                            _c(
                                              "span",
                                              { attrs: { id: "mallPrice" } },
                                              [_vm._v(_vm._s(propInfo.value))]
                                            ),
                                          ]
                                        )
                                      : propInfo.type === 12
                                      ? _c(
                                          "span",
                                          { staticClass: "marketPrice" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.choiceCurrencyVal) +
                                                _vm._s(propInfo.value)
                                            ),
                                          ]
                                        )
                                      : _c("span", {
                                          staticClass: "propVal",
                                          domProps: {
                                            innerHTML: _vm._s(propInfo.value),
                                          },
                                        }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.isJZShoppingOptionOpen && _vm.showOptionFlag
                      ? _c("div", {
                          staticClass: "mallOptionBox J-op-box",
                          domProps: { innerHTML: _vm._s(_vm.showOptionHtml) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.realNameAuth.allowShowMessage
                      ? _c("div", { staticClass: "pdCommentSwitchBox" }, [
                          !_vm.showDetail
                            ? _c(
                                "div",
                                {
                                  staticClass: "pdCommentSwitch",
                                  on: {
                                    click: _vm.pdCommentSwitchClickHandler,
                                  },
                                },
                                [
                                  _c("div", {
                                    staticClass: "left shuomingIcon",
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "middle" }, [
                                    _vm._v(
                                      _vm._s(_vm.productCommentDetailDesc)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "right rightArrow",
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isOpen
                            ? _c(
                                "div",
                                {
                                  staticClass: "pdCommentSwitch",
                                  style: _vm.styleBorderTop,
                                  on: {
                                    click: _vm.pdCommentSwitchClickHandler2,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "left liuyanIcon" }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "middle" }, [
                                    _vm._v(
                                      _vm._s(_vm.productCumulativeComments) +
                                        _vm._s(_vm.totalSize)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "right rightArrow",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.bookingOpen ||
                    _vm.immediatelyConsultOpen ||
                    _vm.productFormOpen ||
                    _vm.openKnowPayment
                      ? _c(
                          "div",
                          {
                            class: {
                              btnGroup: true,
                              half:
                                (_vm.bookingOpen ||
                                  _vm.productFormOpen ||
                                  _vm.openKnowPayment) &&
                                _vm.immediatelyConsultOpen,
                            },
                          },
                          [
                            _vm.openKnowPayment
                              ? _c("input", {
                                  staticClass: "g_button",
                                  style: _vm.productBookingStyle,
                                  attrs: {
                                    id: "productBooking",
                                    _pid: _vm.productId,
                                    type: "button",
                                    value: _vm.knowPaymentText,
                                  },
                                  on: { click: _vm.productBookingClickHandler },
                                })
                              : [
                                  _vm.immediatelyConsultOpen
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "g_button g_conSultBtn",
                                          attrs: {
                                            id: "consultImmediatelyBtn",
                                            _pid: _vm.productId,
                                            type: "button",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.advisoryTxt))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.bookingOpen || _vm.productFormOpen
                                    ? _c("input", {
                                        staticClass: "g_button",
                                        attrs: {
                                          id: "productBooking",
                                          _pid: _vm.productId,
                                          type: "button",
                                          value: _vm.shopBtnTypeText,
                                        },
                                        on: {
                                          click: _vm.productBookingClickHandler,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm.list.length >= 1 && _vm.pageIcon
                  ? [
                      _c("div", { attrs: { id: "prevAndNextDiv" } }, [
                        _c("table", { staticClass: "paginateTable" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  attrs: {
                                    colspan: "2",
                                    align: "center",
                                    valign: "middle",
                                  },
                                },
                                [
                                  _c("div", {
                                    staticClass: "pagenation",
                                    class: {
                                      disable: _vm.isDisablePagenation,
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.nextPage + _vm.lastPage
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.showDetail
                  ? _c("html-render", {
                      staticClass: "richContent remarkBox",
                      attrs: { html: _vm.remark },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isPicTextProduct && !_vm.isFinPay
                  ? _c("div", { style: _vm.picTextProductNotPayWarpStyle }, [
                      _c("div", { staticClass: "knowPayNotPayTips" }),
                      _vm._v(" "),
                      _c("span", { style: _vm.knowPayNotPayTextStyle }, [
                        _vm._v(_vm._s(_vm.LS.viewAllAfterPurchase)),
                      ]),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }