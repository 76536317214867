<template>
    <module-frame :module-id="moduleId">
        <div id="profile">
            <div class="loginHeader webHeaderBg">
                <a class="g_close icon-gClose" @click="closeClick"></a>
                <span class="title pageTitle" v-text="LS.col_member_center"></span>
            </div>
            <div class="profileContent">
                <template v-if="sessionMid == 0 && !manageMode">
                    <div class="noProduct icon-noLoginIcon"></div>
                    <div class="noProductTips">{{ LS.mobi_member_NoLogin }}!</div>
                    <div class="mallButton g_button" @click="mallButtonClick" v-text="LS.memberLogin"></div>
                </template>
                <template v-else-if="sessionMid == 0 && manageMode">
                    <jz-profile-manage :module-id="moduleId"></jz-profile-manage>
                </template>
                <template v-else>
                    <jz-profile-visitor :module-id="moduleId"></jz-profile-visitor>
                </template>
            </div>
        </div>
        <template v-if="memberBulletinOpen">
            <div id="mBulletinConten" class="J_cusBack modifyProfile">
                <div class="loginHeader webHeaderBg">
                    <span class="g_close icon-gClose" @click="mBulletinContenCloseClick"></span>
                    <span class="title pageTitle">{{ bulletinTitle }}</span>
                </div>
                <div v-if="isNoBulletin" class="mallThemeBulletinCotentBg"></div>
                <div :class="bulletinCotentClass" v-html="bulletinContent"></div>
            </div>
        </template>
        <template v-if="isOpenMyForm">
            <div id="formList">
                <div class="loginHeader webHeaderBg profileLoginHeader">
                    <span class="g_close icon-gClose" @click="formListCloseClick"></span>
                    <span class="title pageTitle"></span>
                </div>
                <div class="profileContent profile_content_v3">
                    <ul class="area"></ul>
                </div>
            </div>
        </template>
        <template v-if="isOpenKnowPayment">
            <div id="knowPayList">
                <div class="loginHeader webHeaderBg profileLoginHeader">
                    <span class="g_close icon-gClose" @click="knowPayListCloseClick"></span>
                    <span class="title pageTitle"></span>
                </div>
                <div class="profileContent profile_content_v3">
                    <div v-if="!isOnlyFinishedOrder" class="know_pay_list_kind">
                        <div
                            v-for="(orderNav, index) in orderNavs"
                            :class="{
                                know_pay_list_kind_item_select: index === selectOrderNavIndex,
                            }"
                            class="know_pay_list_kind_item g_color g_main_color_v3"
                            @click="changeOrderNav(index)"
                            >{{ orderNav }}</div
                        >
                    </div>
                    <div style="height: 0.4266rem; background-color: #f4f4f4"></div>
                    <ul v-if="curKnowList.length" class="productList">
                        <template v-for="item in curKnowList">
                            <li>
                                <a :href="item.jumpUrl" :onclick="getUrlArgsOnclickStr(item)">
                                    <div class="know_pay_list_item">
                                        <div>
                                            <span class="know_pay_list_item_header">
                                                <span>{{ LS.mallStlOrderCode }}{{ item.code }}</span>
                                                <span class="know_pay_list_item_pay_status g_color g_main_color_v3">{{
                                                    getPayStatusText(false, item.payStatus)
                                                }}</span>
                                            </span>
                                        </div>
                                        <div class="know_pay_list_item_main">
                                            <div
                                                style="
                                                    position: relative;
                                                    border: solid 1px #eee;
                                                    margin-right: 0.7893rem;
                                                "
                                            >
                                                <div
                                                    class="know_pay_list_item_product_img"
                                                    :style="'background-image: url(' + item.picPath + ')'"
                                                ></div>
                                                <span
                                                    style="
                                                        font-size: 0.4rem;
                                                        color: #fff;
                                                        background-color: rgba(0, 0, 0, 0.5);
                                                        border-radius: 0.1rem;
                                                        padding: 0.1rem 0.2rem;
                                                        position: absolute;
                                                        bottom: 0.2rem;
                                                        right: 0.2rem;
                                                    "
                                                >
                                                    {{ item.labelText }}
                                                </span>
                                            </div>
                                            <div class="know_pay_list_item_product_info">
                                                <span class="know_pay_list_item_product_name">{{
                                                    item.productName
                                                }}</span>
                                                <span class="know_pay_list_item_product_buy_time"
                                                    >{{ LS.purchaseTime }}: {{ formatDate(item.payTime) }}</span
                                                >
                                                <span class="know_pay_list_item_product_price g_color g_main_color_v3"
                                                    >¥{{ item.price.toFixed(2) }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <div style="height: 0.4266rem; background-color: #f4f4f4"></div>
                        </template>
                    </ul>
                    <div v-else class="empty_tips">
                        <div class="img"></div>
                        <div class="text">{{ LS.no_know_pd_list }}</div>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="isOpenMyForm">
            <div id="formDetail" :class="{ form_detail_preview: formPreviewState }">
                <div class="loginHeader webHeaderBg profileLoginHeader">
                    <span class="g_close icon-gClose" @click="formDetailCloseClick"></span>
                    <span class="title pageTitle"></span>
                </div>
                <div v-show="!isEditState" class="profileContent profile_content_v3"></div>
                <div v-show="isEditState">
                    <form-detail-edit v-if="isEditState" @submit-success="onEditSuccess" />
                </div>
                <button v-if="formPreviewState" class="form_edit_button" @click="setEditState">{{
                    LS.edit_form_result
                }}</button>
            </div>
        </template>
        <div id="modifyPersInfo" class="J_cusBack modifyProfile">
            <div class="loginHeader webHeaderBg">
                <span class="g_close icon-gClose" @click="modifyPersInfoCloseClick"></span>
                <span class="title pageTitle"></span>
            </div>
            <div class="profileContent">
                <div class="g_globalLine">
                    <span class="icon-acctIcon g_mainColor"></span>
                    <input
                        class="acct g_input"
                        :class="modifyDisCls"
                        type="text"
                        maxlength="20"
                        :disabled="modifyDisabled"
                        :value="memberAcct"
                    />
                    <div v-if="canResetAcct" class="acctDes" v-text="LS.memberProfile_onlyResetOnce"></div>
                </div>
                <template v-for="item in displayList">
                    <div
                        v-if="item.isMobile && (!isCn || !signByMobile)"
                        id="editMobile"
                        :key="item.fieldKey"
                        class="g_globalLine_wrap"
                    >
                        <div class="g_globalLine mobileLine">
                            <div class="mobileCtVal" v-text="mobileCtCode"></div><div class="mobileCtBorder"></div
                            ><select
                                id="mobileCt"
                                name="mobileCt"
                                class="g_input itemInput infoItem select_v3 icon-select"
                                :class="item.mustItemClass"
                                @click="mobileCtClick"
                                @blur="mobileCtBlur"
                                @change="mobileCtChange"
                            >
                                <option v-for="ct in mobileCtNameList" :key="ct.lskey" :value="ct.ctName">{{
                                    ct.ctShowName + ct.ctCode
                                }}</option> </select
                            ><span :class="item.customPropName" v-text="item.name"></span
                            ><input
                                id="mobile"
                                type="text"
                                maxlength="11"
                                class="g_input itemInput infoItem"
                                :class="item.mustItemClass"
                                :value="preMobile"
                                :placeholder="item.propPlaceholder"
                                :name="item.fieldKey"
                            />
                            <span :class="item.itemIcon"></span>
                        </div>
                    </div>
                    <div v-else :key="item.fieldKey" class="g_globalLine_wrap">
                        <div class="g_globalLine" :class="fileClass(item)">
                            <div
                                v-if="item.isMobile && signByMobile"
                                id="editMobileBtn"
                                class="editMobileBtn editMobileBtnThemev1"
                                @click="editMobileBtnClick"
                                >修改</div
                            >
                            <template v-if="item.pt == 0">
                                <span
                                    v-if="JSON.parse(item.pce).mc == 0"
                                    style="display: inline-block"
                                    class="g_input"
                                    >{{ item.values || '-' }}</span
                                >
                                <input
                                    v-else
                                    type="text"
                                    class="g_input itemInput infoItem"
                                    :class="item.disClass + ' ' + item.mustItemClass"
                                    :name="item.fieldKey"
                                    maxlength="50"
                                    :placeholder="item.propPlaceholder"
                                    :value="item.values"
                                    :propTip="item.propTip"
                                    :disabled="item.disable"
                                />
                                <span :class="item.itemIcon"></span>
                            </template>
                            <template v-else-if="item.pt == 1">
                                <span
                                    v-if="JSON.parse(item.pce).mc == 0"
                                    style="display: inline-block"
                                    class="g_input"
                                    >{{ item.values || '-' }}</span
                                >
                                <select
                                    v-else
                                    :name="item.fieldKey"
                                    :placeholder="item.propPlaceholder"
                                    class="g_input itemInput infoItem"
                                    :class="item.mustItemClass"
                                    :propTip="item.propTip"
                                >
                                    <option>{{
                                        item.must
                                            ? LS.mobi_member_PleaseInput + ' ' + item.name + LS.mobi_member_Must
                                            : LS.mobi_member_PleaseInput + ' ' + item.name
                                    }}</option>
                                    <option
                                        v-for="prop in item.propOptions"
                                        :key="prop"
                                        :value="prop"
                                        :selected="item.values == prop"
                                        v-text="prop"
                                    ></option>
                                </select>
                                <span :class="item.itemIcon"></span>
                            </template>
                            <template v-else-if="item.pt == 2">
                                <div class="member_file_tips">{{ getFileTips(item) }}</div>
                                <div :id="'member_file_contentwrap-' + item.id" class="member_file_contentwrap">
                                    <input
                                        :id="item.fieldKey"
                                        :name="item.fieldKey"
                                        :propTip="item.propTip"
                                        class="itemEdit infoItem member_file_input"
                                        :class="item.mustItemClass"
                                        :data-name="item.id"
                                        :value="item.values"
                                    />
                                    <div class="member_file_btn" :class="hideAddFileIconClass(item)">
                                        <div class="member_file_btnupload uploadify-button"></div>
                                        <span v-text="addFileText"></span>
                                    </div>
                                    <div class="member_file_content" :class="showFileDetailClass(item)">
                                        <div class="member_file_meta">
                                            <div class="member_file_name">{{ getName(item) }}</div>
                                            <div class="member_file_size">{{ getSize(item) }}</div>
                                        </div>
                                        <div class="member_file_icon">
                                            <svg class="member_file_close" :class="hideDeleteIconClass(item)">
                                                <use xlink:href="#jzm-v25"></use>
                                            </svg>
                                            <a
                                                ref="nofllow"
                                                class="member_file_preview_link"
                                                href="javascript:void(0)"
                                                target="_blank"
                                            >
                                                <svg class="member_file_preview">
                                                    <use xlink:href="#jzm-vicon_preview"></use>
                                                </svg>
                                            </a>
                                            <a
                                                ref="nofllow"
                                                class="member_file_download_link"
                                                :href="getDownloadUrl(item)"
                                                target="_blank"
                                            >
                                                <svg class="member_file_download">
                                                    <use xlink:href="#jzm-v16"></use>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <span
                                v-if="item.customPropName != ''"
                                :class="item.customPropName"
                                v-text="item.name"
                            ></span>
                        </div>
                    </div>
                </template>
                <input
                    type="button"
                    class="g_button sendIcon profileSubmit"
                    :class="{ itemInput: manageMode }"
                    :value="LS.mobi_avatarSave"
                    @click="profileSubmitClick"
                />
            </div>
        </div>
        <div class="loginErrorLine">
            <div class="errorTips"></div>
        </div>
        <template v-if="signByMobile">
            <div id="editMobilePanel" class="J_cusBack mobilePanel modifyPersInfo" style="display: none">
                <div class="loginHeader webHeaderBg profileLoginHeader">
                    <span class="g_close icon-gClose" @click="editMobilePanelCloseClick"></span>
                    <span class="title pageTitle">修改手机号</span>
                </div>
                <div class="profileContent">
                    <div class="m_pointBoxContainer">
                        <div id="inputMobile">
                            <div class="defMobi">当前手机号：{{ preMobile }}</div>
                        </div>
                        <div class="f_editArea">
                            <select
                                id="mobileCt"
                                class="itemInput infoItem select_v3 icon-select mustItem mobileCtNew"
                                @click="mobileCtClick"
                                @blur="mobileCtBlur"
                                @change="mobileCtChange"
                            >
                                <option
                                    v-for="ct in mobileCtNameList"
                                    :key="ct.ctName"
                                    :value="ct.ctName"
                                    :selected="mobileCtCode == ct.ctCode"
                                    v-text="ct.ctCode"
                                ></option>
                            </select>
                            <input
                                id="mobile"
                                class="g_input editInput mobileCtInput"
                                maxlength="11"
                                placeholder="请输入新的手机号码"
                                :value="preMobile"
                                @input="mobileCtInput"
                            />
                            <div
                                id="cleanBtn"
                                class="cleanBtn"
                                :style="{
                                    display: preMobile == '' ? 'none' : '',
                                }"
                                @click="cleanBtnClick"
                                >x</div
                            >
                        </div>
                        <div id="mobileValidate" style="display: none">
                            <div class="validateCodeMsg"> 请输入<span id="newMobile"></span>收到的短信验证码 </div>
                            <div class="f_editArea">
                                <div class="validateCode">验证码</div>
                                <input
                                    id="validateCodeInput"
                                    class="g_input editInput validateCodeInput"
                                    maxlength="8"
                                    placeholder="请输入验证码"
                                    @input="validateCodeInput"
                                />
                                <div
                                    id="cleanValidateCodeInput"
                                    style="display: none"
                                    class="cleanBtn cleanBtnValidateCode"
                                    @click="cleanValidateCodeInputClick"
                                    >x</div
                                >
                                <div
                                    id="messageAuthCodeClick"
                                    class="sendValidateCode sendValidateCode1"
                                    :title="LS.mobi_getMobileCode"
                                    @click="messageAuthCodeClick"
                                    >{{ LS.mobi_getMobileCode }}</div
                                >
                                <div id="showCaptcha" style="display: none"></div>
                            </div>
                        </div>
                        <div
                            id="saveMobileBtn"
                            class="f_saveBtn mobileCtBtn g_bgColor un-change"
                            @click="saveMobileBtnClick"
                            >确定</div
                        >
                    </div>
                </div>
            </div>
        </template>
        <div id="modifyPsw" class="J_cusBack modifyProfile">
            <div class="loginHeader webHeaderBg">
                <span class="g_close icon-gClose" @click="modifyProfileCloseClick"></span>
                <span class="title pageTitle">{{ LS.mobi_membert_ResetPassword }}</span>
            </div>
            <div class="profileContent">
                <div v-if="needOldPwd" class="g_globalLine">
                    <input
                        id="oldPsw"
                        class="g_input itemInput mustItem"
                        type="password"
                        autocomplete="off"
                        :maxlength="MemberDef.Limit.PWD_MAXLEN"
                        :placeholder="LS.mobi_member_OldPassword"
                    />
                    <span class="icon-pswIcon g_mainColor"></span>
                </div>
                <div class="g_globalLine">
                    <input
                        id="newPsw"
                        class="g_input itemInput mustItem"
                        type="password"
                        autocomplete="off"
                        :maxlength="MemberDef.Limit.PWD_MAXLEN"
                        :placeholder="LS.mobi_memberProfile_password"
                    />
                    <span class="icon-pswIcon g_mainColor"></span>
                </div>
                <div class="g_globalLine">
                    <input
                        id="confirmPsw"
                        class="g_input itemInput mustItem"
                        type="password"
                        autocomplete="off"
                        :maxlength="MemberDef.Limit.PWD_MAXLEN"
                        :placeholder="LS.mobi_memberSignup_retypePassword"
                    />
                    <span class="icon-pswIcon g_mainColor"></span>
                </div>
                <div class="g_globalLine">
                    <input
                        type="button"
                        class="g_button profileSubmit"
                        :class="{ sendIcon: !manageMode }"
                        :value="LS.mobi_avatarSave"
                        disabled
                        @click="modifyPswSumbitClick"
                    />
                </div>
            </div>
        </div>
        <div id="viewMyReward" class="J_cusBack viewMyReward">
            <div class="loginHeader webHeaderBg profileLoginHeader">
                <span class="g_close icon-gClose" @click="viewMyRewardCloseClick"></span>
                <span class="title pageTitle"></span>
            </div>
            <div class="view-reward-content">
                <ul class="reward-list"></ul>
            </div>
        </div>
        <div id="modifyIntegral" class="J_cusBack modifyProfile">
            <div class="loginHeader webHeaderBg">
                <span class="g_close icon-gClose" @click="modifyIntegralCloseClick"></span>
                <span class="title pageTitle"></span>
            </div>
            <div class="profileContent">
                <div class="itgPanel"></div>
            </div>
        </div>
        <div id="modifyIntegralRule" class="J_cusBack modifyProfile">
            <div class="loginHeader webHeaderBg profileLoginHeader">
                <span class="g_close icon-gClose" @click="modifyIntegralRuleCloseClick"></span>
                <span class="title pageTitle"></span>
            </div>
            <div class="profileContent profile_content_v3">
                <div class="itgPanelRule"></div>
                <span v-html="richContent"></span>
            </div>
        </div>
        <template v-if="isCrownVer">
            <div v-if="openCollection" id="modifyCollection" class="J_cusBack modifyProfile collectionV2">
                <div class="loginHeader webHeaderBg">
                    <span class="g_close icon-gClose" @click="modifyCollectionCloseClick"></span>
                    <span class="title pageTitle"></span>
                </div>
                <div class="profileContent">
                    <img style="display: none" :src="imgRoot" alt="" />
                    <div class="collectionList"></div>
                </div>
            </div>
            <div v-if="openCoupon" id="modifyCoupon" class="J_cusBack modifyProfile">
                <div class="loginHeader webHeaderBg">
                    <span class="g_close icon-gClose" @click="modifyCouponCloseClick"></span>
                    <span class="title pageTitle"></span>
                </div>
                <div class="couponListPanel"></div>
            </div>
            <div v-if="isOpenCutPrice && !manageMode && isCn" id="modifyCutPrice" class="J_cusBack modifyProfile">
                <div class="loginHeader webHeaderBg">
                    <span class="g_close icon-gClose" @click="modifyCutPriceCloseClick"></span>
                    <span class="title pageTitle"></span>
                </div>
                <div class="cutPriceListPanel"></div>
            </div>
            <div v-if="isOpenGroupby && !manageMode && isCn" id="modifyGroupBuy" class="J_cusBack modifyProfile">
                <div class="loginHeader webHeaderBg">
                    <span class="g_close icon-gClose" @click="modifyGroupBuyCloseClick"></span>
                    <span class="title pageTitle"></span>
                </div>
                <div class="groupBuyListPanel"></div>
            </div>
        </template>
        <div id="editMemberHeadPic" class="J_cusBack modifyProfile">
            <div class="loginHeader">
                <span class="g_close icon-gClose" @click="editMemberHeadPicCloseClick"></span>
            </div>
            <div id="picArea" class="picArea">
                <img id="headPic" :_src="imgPath" />
                <div class="cover cover1"></div>
                <div class="cover cover2"></div>
                <img
                    id="coverImg"
                    class="coverImg"
                    style="opacity: 0.5"
                    :src="resImageRoot + '/image/member/layer.png'"
                />
                <div class="cover cover4"></div>
                <div class="cover cover5"></div>
                <div class="coverRing"></div>
                <div id="coverBox" class="coverBox">
                    <div id="dragLeftTop" class="dragPoint" style="top: -3px; left: -3px"></div>
                    <div id="dragRightTop" class="dragPoint" style="top: -3px; right: -3px; cursor: ne-resize"></div>
                    <div id="dragLeftBot" class="dragPoint" style="bottom: -3px; left: -3px; cursor: ne-resize"></div>
                    <div id="dragRightBot" class="dragPoint" style="bottom: -3px; right: -3px"></div>
                    <div id="dragTopCenter" class="dragPoint" :style="dragTopCenterStyle"></div>
                    <div id="dragRightCenter" class="dragPoint" :style="dragRightCenterStyle"></div>
                    <div id="dragLeftCenter" class="dragPoint" :style="dragLeftCenterStyle"></div>
                    <div id="dragBotCenter" class="dragPoint" :style="dragBotCenterStyle"></div>
                </div>
            </div>
            <div class="editHeadPicFooter">
                <div class="editBtn modifyBtn">
                    {{ LS.mobi_avatarReplace }}
                    <span id="file_upload" class="fileUploadBtn" style="width: 5rem"></span>
                </div>
                <div class="editBtn saveBtn" :oldimgid="oldimgid" :mid="sessionMid" v-text="LS.mobi_avatarSave"></div>
            </div>
        </div>
        <div id="bindOtherAcct" class="J_cusBack modifyProfile" style="display: none">
            <div class="loginHeader webHeaderBg">
                <span id="bindAcctBack" class="g_close icon-gClose"></span>
                <span class="title pageTitle" v-text="LS.mobi_member_OtherLgnBindAcct"></span>
            </div>
            <div class="loginContent"></div>
        </div>
        <div id="mSignUp" class="J_cusBack modifyProfile" style="display: none">
            <div class="loginHeader webHeaderBg">
                <span id="signUpBack" class="g_close icon-gClose"></span>
                <span class="title pageTitle" v-text="LS.col_member_signup"></span>
            </div>
            <div class="loginContent"></div>
            <div id="checkEmail" class="checkEmail"></div>
            <a id="emailAddr" class="emailAddr" style="display: none" target="_blank">地址</a>
        </div>
    </module-frame>
</template>
<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import ModuleFrame from '@/modules/frame/index.vue';
import JzProfileManage from './manage/jz-profile.vue';
import JzProfileVisitor from './visitor/jz-profile.vue';
import { MemberPropDef } from '../../memberDef';
import { preprocessUrl } from '@/shared/url';

import { checkBit } from '@/shared/fai';
import { SiteVerDef } from '@/def/ver';
import FormDetailEdit from './formDetailEdit.vue';

import {
    setMyFormTipState,
    initFormItemEvent,
    persInfoChangeMobileCt,
    clickEditMobile,
    setPersonalInfo,
    memberProfileSubmit,
    revertBackground,
    loadItg,
    modifyListener,
    initMemberUploadImg,
    memberModuleReturnUrlInit,
    initMemberHeadPic,
    loadKnowPayList,
    showRenderDetail,
} from '../method';
import { selectAnimationCtrl, selectAnimation, checkMustItem, bindOtherAcct } from '../../method';
import mobileEditFuc from '../mobileEditFuc';
import { hidePlaceholder } from '@/shared/misc/placeholder';
import { switchJump } from '@/shared/linkSetting/index';
import { MemberDef } from '../../memberDef';
import loadPdCollectionList from '../loadPdCollectionList';
import { changeHref } from '@/features/router/universal';
import { memberFileUpload } from '@/system/member/fileUpload';
import { dayjs } from '@jz/utils';
import { setProfileVM } from '../eventBus';
import { createUrlArgsOnclickStr } from '@/modules/comm';
let manageMode = VITE_APP_MODE !== 'visitor';

export default {
    name: 'Profile',
    components: {
        ModuleFrame,
        JzProfileManage,
        JzProfileVisitor,
        FormDetailEdit,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            manageMode,
            mobileCtCodeValues: '',
            imgRoot: '',
            mobiResRoot: '',
            MemberDef,
            knowList: [],
            orderNavs: ['全部', '售后中', '已完成', '已关闭'],
            selectOrderNavIndex: 0,
            isEditState: false,
        };
    },
    computed: {
        ...mapState('app', ['LS', 'lcid', '_ver', 'resImageRoot']),
        ...mapState('form', ['openMemberModifySubmit']),
        isCrownVer() {
            return (
                this._ver == SiteVerDef.CROWN ||
                this._ver == SiteVerDef.OEM_CROWN ||
                this._ver == SiteVerDef.CROWN_BUY_OUT
            );
        },
        isCn() {
            return this.lcid == 2052;
        },
        bulletinCotentClass() {
            return this.isMallTheme ? ['mallThemeBulletinCotent'] : ['bulletinCotent'];
        },
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        renderOptions() {
            return this.module.renderOptions || {};
        },
        indexUrl() {
            return this.renderOptions.indexUrl;
        },
        isOpenGroupby() {
            return this.renderOptions.isOpenGroupBuy || false;
        },
        isOpenCutPrice() {
            return this.renderOptions.isOpenCutPrice || false;
        },
        openCollection() {
            return this.renderOptions.openCollection || false;
        },
        openCoupon() {
            return this.renderOptions.openCoupon || false;
        },
        needOldPwd() {
            return this.renderOptions.needOldPwd || false;
        },
        preMobile() {
            return this.renderOptions.preMobile || '';
        },
        mobileCtNameList() {
            return this.renderOptions.mobileCtNameList || [];
        },
        signByMobile() {
            return this.renderOptions.signByMobile || false;
        },
        displayList() {
            return this.renderOptions.displayList || [];
        },
        canResetAcct() {
            return this.renderOptions.canResetAcct || false;
        },
        mobileCtCode() {
            return this.renderOptions.mobileCtCode || '';
        },
        memberAcct() {
            return this.renderOptions.memberAcct || '';
        },
        imgPath() {
            return this.renderOptions.imgPath || '';
        },
        richContent() {
            return this.renderOptions.richContent || '';
        },
        oldimgid() {
            return this.renderOptions.oldImgId || '';
        },
        remarkUpdateTips() {
            return this.renderOptions.remarkUpdateTips || '';
        },
        isOpenMyForm() {
            return this.renderOptions.isOpenMyForm || false;
        },
        isOpenKnowPayment() {
            return this.renderOptions.isOpenKnowPayment || false;
        },
        memberBulletinOpen() {
            return this.renderOptions.memberBulletinOpen || false;
        },
        sessionMid() {
            return this.renderOptions.sessionMid || 0;
        },
        bulletinTitle() {
            return this.renderOptions.bulletinTitle || '';
        },
        bulletinContent() {
            return this.renderOptions.bulletinContent || '';
        },
        modifyDisabled() {
            return this.canResetAcct ? false : true;
        },
        modifyDisCls() {
            return this.canResetAcct ? '' : 'canNotReset';
        },
        rulesDetail() {
            return this.renderOptions.rulesDetail || '';
        },
        rulesName() {
            return this.renderOptions.rulesName || '';
        },
        memberInfo() {
            return this.renderOptions.memberInfo || {};
        },
        headPic() {
            return this.renderOptions.headPic || {};
        },
        propParam() {
            return this.renderOptions.propParam || {};
        },
        isMallTheme() {
            return this.renderOptions.isMallTheme || false;
        },
        isNoBulletin() {
            return this.bulletinContent != null && this.bulletinContent != '';
        },
        profileUrl() {
            return this.renderOptions.profileUrl || '';
        },
        showCaptcha() {
            return this.renderOptions.showCaptcha || false;
        },
        js_faiEncrypt() {
            return this.renderOptions.js_faiEncrypt || '';
        },
        js_faiEncrypt_N() {
            return this.renderOptions.js_faiEncrypt_N || '';
        },
        choiceCurrencyVal() {
            return this.renderOptions.choiceCurrencyVal || '￥';
        },
        statusUpdateTips() {
            return this.LS.member_status_udateTips;
        },
        itgName() {
            return this.renderOptions.itgName || '';
        },
        fileItemList() {
            return this.displayList.filter((item) => {
                return item.pt == 2;
            });
        },
        isOtherLogin() {
            return this.renderOptions.isOtherLogin || '';
        },
        optionList() {
            return this.renderOptions.optionList || [];
        },
        noRemark() {
            return this.renderOptions.noRemark || false;
        },
        signNeenMobile() {
            return this.renderOptions.signNeenMobile;
        },
        dragTopCenterStyle() {
            return {
                top: '-3px',
                left: '50%',
                'margin-left': '-3px',
                cursor: 'n-resize',
            };
        },
        dragRightCenterStyle() {
            return {
                top: '50%',
                right: '-3px',
                'margin-top': '-3px',
                cursor: 'e-resize',
            };
        },
        dragLeftCenterStyle() {
            return {
                left: '-3px',
                top: '50%',
                'margin-top': '-3px',
                cursor: 'w-resize',
            };
        },
        dragBotCenterStyle() {
            return {
                left: '50%',
                bottom: '-3px',
                'margin-left': '-3px',
                cursor: 's-resize',
            };
        },
        isOnlyFinishedOrder() {
            return this.knowList.every((item) => item.payStatus == 1);
        },
        curKnowList() {
            if (this.selectOrderNavIndex !== 0) {
                return this.knowList.filter((item) => {
                    return item.payStatus == [0, 3, 1, 2][this.selectOrderNavIndex];
                });
            } else {
                return this.knowList;
            }
        },
        formPreviewState() {
            return !this.isEditState && this.openMemberModifySubmit;
        },
        addFileText() {
            return `+ ${this.LS.js_add_file}`;
        },
    },
    created() {
        this.displayList.forEach((item) => {
            let { flag, pp = '', fieldKey, pc = '', id } = item;
            let must = checkBit(flag, MemberPropDef.Flag.REQUIRED);
            let mustItemClass = must ? ['mustItem'] : [];
            let isDisabled = false;

            let propPlaceholder = pp == '' ? '' : pp;
            let itemIcon = '';
            let customPropName = '';
            if (propPlaceholder == '' || propPlaceholder == null) {
                propPlaceholder = this.LS.noContentPlaceholder;
            }

            let disStyle = false;
            let disClass = [];

            if (this.signByMobile && fieldKey == 'mobile') {
                disStyle = true;
                disClass = ['canNotReset'];
                isDisabled = true;
            }

            if (fieldKey == 'email') {
                itemIcon = 'icon-emailIcon g_mainColor';
            } else if (fieldKey == 'phone') {
                itemIcon = 'icon-customIcon g_mainColor';
                customPropName = 'customIcon';
            } else if (fieldKey == 'name') {
                itemIcon = 'icon-nameIcon g_mainColor';
            } else if (fieldKey == 'mobile') {
                itemIcon = 'icon-iphone2 g_mainColor';
                customPropName = 'customIcon';
            } else {
                itemIcon = 'icon-customIcon g_mainColor';
                customPropName = 'customIcon';
            }

            let propContent = pc.split('\n');
            item.propOptions = propContent;

            item.must = must;
            item.mustItemClass = mustItemClass;
            item.propPlaceholder = propPlaceholder;
            item.itemIcon = itemIcon;
            item.customPropName = customPropName;
            item.disStyle = disStyle;
            item.disClass = disClass;
            item.isMobile = item.type == MemberPropDef.Type.SYS_MOBILE;
            let propParam = this.propParam[id] || {};
            item.values = propParam.values == 'none' ? '' : propParam.values || '';
            item.propTip = propParam.propTip || '';
            item.disable = isDisabled;
        });
    },
    mounted() {
        if (this.isOpenMyForm) {
            initFormItemEvent(this.LS.remark_message, this.LS.no_remark_message, this.LS.memberCurrentStatus);
        }
        if (this.signByMobile) {
            mobileEditFuc.needCaptcha = this.needOldPwd;
            mobileEditFuc.init(this.js_faiEncrypt, this.js_faiEncrypt_N);
        }
        if (this.memberInfo.headPic != null) {
            initMemberHeadPic(this.memberInfo.headPic);
        }

        if (this.isCrownVer && !(VITE_APP_MODE !== 'visitor')) {
            let productCollectionList = this.memberInfo.productCollections || [];
            productCollectionList.reverse();
            let collection = new loadPdCollectionList(this.sessionMid, productCollectionList);
            collection.init();
        }

        window.choiceCurrencyVal = this.choiceCurrencyVal;

        if (!(VITE_APP_MODE !== 'visitor')) {
            loadItg(this.sessionMid, this.LS.integral_current + this.itgName, this.rulesName, this.rulesDetail != '');
        }

        hidePlaceholder();
        switchJump();
        modifyListener(this.sessionMid, this.headPic);
        checkMustItem('#modifyPsw');
        if (!(VITE_APP_MODE !== 'visitor')) {
            initMemberUploadImg(MemberDef.Limit.IMG_MAX_SIZE, MemberDef.Limit.IMG_TYPE_VALId);
        }
        this.initFileUpload();
        memberModuleReturnUrlInit(this.indexUrl, this.returnUrl);
        if (this.isOtherLogin != '') {
            bindOtherAcct(
                this.sessionMid,
                this.optionList,
                MemberDef.Limit.REMARK_MAXLEN,
                this.mobileCtNameList,
                this.signNeenMobile
            );
        }
        setProfileVM(this);
    },
    methods: {
        ...mapMutations('form', ['resetFormEdit']),
        hideAddFileIconClass(item) {
            return this.propParam[item.id]?.haveFile ? 'member_file_btn-hide' : '';
        },
        showFileDetailClass(item) {
            return this.propParam[item.id]?.haveFile ? 'member_file_content-show' : '';
        },
        hideDeleteIconClass(item) {
            return this.propParam[item.id]?.haveFile && this.propParam[item.id]?.memberEdit == 0
                ? 'member_file_close-hide'
                : '';
        },
        getName(item) {
            return this.propParam[item.id]?.fileName;
        },
        getSize(item) {
            return this.propParam[item.id]?.fileSize;
        },
        getDownloadUrl(item) {
            return this.propParam[item.id]?.fileDownloadUrl;
        },
        initFileUpload() {
            this.fileItemList.forEach((item) => {
                if (item) {
                    memberFileUpload({
                        moduleId: this.moduleId,
                        fileSizeLimit: this.propParam[item.id]?.fileSizeLimit,
                        fileUpInfo: (item.pc && JSON.parse(item.pc)?.fup) || {},
                        propId: item.id,
                        previewLinkInfo:
                            item.id &&
                            this.propParam[item.id] &&
                            JSON.parse(this.propParam[item.id].previewLinkInfo || {}),
                    });
                }
            });
        },
        getFileTips(item) {
            let fileItemOption = (item.pc && JSON.parse(item.pc)?.fup) || {};
            return fileItemOption.tt;
        },
        fileClass(item) {
            return item.pt == 2 ? ' memeber_file_line memeber_file_line-old' : '';
        },
        closeClick() {
            changeHref(this.indexUrl);
        },
        mallButtonClick() {
            changeHref(preprocessUrl({ path: '', oldPath: '' }) + `/login.jsp?returnUrl=${this.profileUrl}`);
        },
        mBulletinContenCloseClick() {
            jm('#mBulletinConten').hide();
            jm('#profile').show();
        },
        formListCloseClick() {
            this.resetFormEdit();
            jm('#formList').hide();
            setMyFormTipState(this.remarkUpdateTips, this.statusUpdateTips);
            jm('#profile').show();
        },
        knowPayListCloseClick() {
            jm('#knowPayList').hide();
            revertBackground();
            jm('#profile').show();
        },
        formDetailCloseClick() {
            if (this.isEditState) {
                // 返回非编辑状态
                this.isEditState = false;
            } else {
                // 返回上一页
                jm('#formDetail').hide();
                jm('#formList').show();
            }
        },
        modifyPersInfoCloseClick() {
            jm('#modifyPersInfo').hide();
            jm('#profile').show();
        },
        mobileCtClick(e) {
            selectAnimationCtrl(e.target);
        },
        mobileCtBlur(e) {
            selectAnimation(e.target);
        },
        mobileCtChange() {
            persInfoChangeMobileCt();
        },
        editMobileBtnClick(e) {
            clickEditMobile(e.target, this.signByMobile);
        },
        profileSubmitClick() {
            setPersonalInfo(this.sessionMid);
        },
        editMobilePanelCloseClick() {
            jm('#editMobilePanel').hide();
            jm('#modifyPersInfo').show();
            mobileEditFuc.revert();
        },
        mobileCtInput() {
            mobileEditFuc.infoInputChange();
        },
        cleanBtnClick() {
            mobileEditFuc.cleanMobileInput();
        },
        validateCodeInput() {
            mobileEditFuc.infoInputChange();
        },
        cleanValidateCodeInputClick() {
            mobileEditFuc.cleanValidateCodeInput();
        },
        messageAuthCodeClick() {
            mobileEditFuc.getMobileCode(this.showCaptcha);
        },
        modifyProfileCloseClick() {
            jm('#modifyPsw').hide();
            jm('#profile').show();
        },
        modifyPswSumbitClick() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                memberProfileSubmit(this.sessionMid, true, this.needOldPwd);
            }
        },
        viewMyRewardCloseClick() {
            jm('#viewMyReward').hide();
            jm('#profile').show();
        },
        modifyIntegralCloseClick() {
            jm('#modifyIntegral').hide();
            jm('#profile').show();
        },
        modifyIntegralRuleCloseClick() {
            jm('#modifyIntegralRule').hide();
            jm('#modifyIntegral').show();
        },
        modifyCollectionCloseClick() {
            jm('#modifyCollection').hide();
            jm('#profile').show();
        },
        modifyCouponCloseClick() {
            jm('#modifyCoupon').hide();
            jm('#profile').show();
        },
        modifyCutPriceCloseClick() {
            jm('#modifyCutPrice').hide();
            jm('#profile').show();
        },
        modifyGroupBuyCloseClick() {
            jm('#modifyGroupBuy').hide();
            jm('#profile').show();
        },
        editMemberHeadPicCloseClick() {
            jm('#editMemberHeadPic').hide();
            jm('#profile').show();
            if (jm('#p_footer_nav_wrap').attr('data-display') !== 'none') {
                jm('#p_footer_nav_wrap').show();
            } else {
                jm('#p_footer_nav_wrap').removeAttr('data-display');
            }
        },
        saveMobileBtnClick() {
            mobileEditFuc.save();
        },
        changeOrderNav(index) {
            this.selectOrderNavIndex = index;
            loadKnowPayList();
        },
        getPayStatusText(hiddenRefundStatus, payStatus) {
            if (!hiddenRefundStatus) {
                return {
                    ['0']: this.LS.nonPayment,
                    ['1']: this.LS.completed,
                    ['2']: this.LS.closed,
                    ['3']: this.LS.refundding,
                }[payStatus];
            } else {
                return payStatus == 1 ? this.LS.completed : this.LS.nonPayment;
            }
        },
        formatDate(timeStamp) {
            return dayjs(timeStamp).format('YYYY-MM-DD HH:mm:ss');
        },
        getUrlArgsOnclickStr(item) {
            if (this.$isServer) {
                return '';
            } else {
                return createUrlArgsOnclickStr(item.pdReqArgs || '', window._openRemoveUrlArgs);
            }
        },
        setEditState() {
            this.isEditState = true;
        },
        onEditSuccess(editSubmitId) {
            if (editSubmitId > 0) {
                const node = Array.from(jm('#formList .profileContent').find('.item')).find(
                    (node) => parseInt(node.getAttribute('_id')) == editSubmitId
                );
                if (node) {
                    showRenderDetail(jm(node), {
                        remarkLabel: this.LS.remark_message,
                        noRemarkMessageText: this.LS.no_remark_message,
                        memberCurrentStatus: this.LS.memberCurrentStatus,
                        haveRead: true,
                    });
                }
            }
            this.isEditState = false;
        },
    },
};
</script>

<style lang="scss">
.mobiCol12 {
    #g_web {
        background-color: #f7f7f7;
        min-height: 100%;
    }
    #webTopBox,
    #webHeader,
    #webBannerBox,
    #webFooterBox,
    #bgMusicWrapper,
    #fixedRightSideBtns {
        display: none;
    }
    .loginHeader,
    .ShipDetailsHeader,
    .BanksDetailHeader {
        text-align: center;
        height: 2.25rem;
        line-height: 2.25rem;
        margin: 0;
        padding: 0;
        position: relative;
    }
    .loginHeader .title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 11.5rem;
        display: inline-block;
        font-weight: 500;
        z-index: 1;
        text-align: center;
    }
    .middleCenter {
        background-color: #f7f7f7 !important;
        color: #333;
        padding-bottom: 1rem;
        height: 100%;
    }
    .memberBasicInfoArea {
        margin-bottom: 0.5rem;
        border-bottom: 1px solid #ececec;
    }
    #knowPayList {
        display: none;
    }
    #knowPayList .profileContent {
        padding: 0;
    }
    #knowPayList .productList {
        border: none;
    }
    #knowPayList ul.productList:first-child {
        padding-top: 0.75rem;
    }
    #knowPayList .productList li {
        display: flex;
        height: initial;
        border: none;
        padding-left: 0.5rem;
        padding-right: 0.625rem;
        line-height: initial;
        padding-top: 0.6rem;
    }
    #knowPayList .productList li a {
        display: flex;
        width: 100%;
    }
    #knowPayList .productList li .leftContent {
        width: 4.25rem;
        height: 4.25rem;
        border-radius: 0.15rem;
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: 1.1rem;
        background-position: center;
    }
    #knowPayList .productList li .rightContent {
        padding-left: 0.375rem;
        flex: 1;
    }
    #knowPayList .productList li .rightContent .knowPaytitle {
        font-size: 0.6rem;
        color: #333333;
        line-height: 0.75rem;
        font-weight: bold;
        padding-top: 0.2rem;
    }
    #knowPayList .productList li .rightContent .knowPayTime {
        font-size: 0.5rem;
        color: #666666;
        font-weight: 500;
        padding-top: 0.375rem;
    }
    #knowPayList .productList li .sliderLine {
        border-bottom: 0.025rem solid #f0f0f0;
    }
    #knowPayment .itemName svg {
        width: 0.6rem;
        height: 0.7rem;
        padding-right: 0.8rem;
        padding-left: 0.3rem;
        fill: #666;
        position: relative;
        top: 0.08rem;
    }
    .profileContent {
        font-size: 0.6rem;
        ul {
            margin-top: 0;
            list-style: none;
            border-top: 1px #ececec solid;
            padding-left: 0;
        }
        li {
            overflow: hidden;
            border-color: #ebebeb;
            height: 2.5rem;
            line-height: 2.5rem;
            border-bottom: 1px #ececec solid;
            background: #fff;
            .itemName {
                color: #666;
                max-width: 70%;
                overflow: hidden;
                text-overflow: ellipsis;
                float: left;
                white-space: nowrap;
            }
            .clickIcon {
                margin-right: 0.45rem;
                color: #666;
                float: right;
            }
        }
        .onLogout {
            width: 100%;
            height: 2.5rem;
            line-height: 2.5rem;
            background: #fff;
            margin-top: 0.5rem;
            color: #666;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            border-bottom: 1px solid #ececec;
            border-top: 1px solid #ececec;
        }
    }
    #myForm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .itemName svg {
            width: 0.8rem;
            height: 0.8rem;
            padding: 0 0.75rem;
            fill: #757575;
            position: relative;
            top: 0.15rem;
        }
    }
    #formList {
        display: none;
        .profileContent {
            padding: 0 0.75rem;
        }
        area {
            border-top: none;
        }
        .item {
            height: auto;
            line-height: initial;
            background: initial;
            .form_name {
                word-break: break-all;
                padding-top: 0.65rem;
                color: #333;
                font-size: 0.65rem;
            }
            .form_date {
                display: flex;
                justify-content: space-between;
                padding-bottom: 0.75rem;
                padding-top: 0.3rem;
                font-size: 0.5rem;
                color: #999;
            }
        }
    }
    #formDetail {
        display: none;
        .profileContent {
            background-color: #fff;
            padding: 1.15rem 0.75rem 0.75rem 0.75rem;
        }
        .item {
            margin-bottom: 0.7rem;
            .name {
                font-size: 0.6rem;
                color: #333;
                margin-bottom: 0.5rem;
            }
            .data {
                white-space: pre-wrap;
                word-break: break-all;
                background: rgba(246, 246, 246, 1);
                font-size: 0.6rem;
                color: #666;
                padding: 0.5rem 0.475rem 0.5rem 0.75rem;
                border-radius: 0.086rem;
            }
            .empty_remark_tips {
                color: #ccc;
            }
        }
        .pay_wrap {
            display: flex;
            align-items: center;
            .pay_type_wrap {
                display: flex;
                align-items: center;
                margin-left: auto;
                flex-shrink: 0;
            }
            .alipay_icon {
                width: 1rem;
                height: 1rem;
                color: #1caaee;
                margin-right: 0.15rem;
                font-size: 0.65rem;
            }
            .wx_pay_icon {
                width: 1rem;
                height: 1rem;
                fill: #fdb251;
                margin-right: 0.15rem;
                font-size: 0.65rem;
            }

            .wxpay_icon {
                width: 1rem;
                height: 1rem;
                color: #38b035;
                margin-right: 0.15rem;
                font-size: 0.65rem;
            }

            .stripe_icon {
                width: 1rem;
                height: 1rem;
                color: #635bff;
                margin-right: 0.15rem;
                font-size: 0.65rem;
            }
        }
    }
    #formDetail {
        .item_file_container {
            .item_file_list {
                border: none;
                li {
                    border: none;
                }
                .item_file_item {
                    padding: 10px 0px;
                    display: flex;
                    align-items: center;
                    &:not(:last-of-type) {
                        border-bottom: 1px solid #eeeeee;
                    }
                    &_iconbox {
                        border-radius: 2px;
                        overflow: hidden;
                        width: 1.8rem;
                        height: 1.8rem;
                        margin-right: 0.5rem;
                        &--icon {
                            display: block;
                            height: 100%;
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .file_icon {
                                width: 1.2rem;
                                height: 1.2rem;
                                fill: #fff;
                            }
                            max-width: 100%;
                            cursor: default;
                        }

                        &--pdf {
                            color: #ff5a5a;
                        }
                        &--ppt {
                            color: #ff9743;
                        }
                        &--video {
                            color: #707ee5;
                        }
                        &--image {
                            color: #23c786;
                        }
                        &--music {
                            color: #1cbe89;
                        }
                        &--gzip {
                            color: #5095ed;
                        }
                        &--txt {
                            color: #3686fd;
                        }
                        &--word {
                            color: #4e99ff;
                        }
                        &--exls {
                            color: #2c9660;
                        }
                        &--file {
                            color: #b9c9d6;
                        }
                        &--image {
                            width: 1.8rem;
                            height: 1.8rem;
                            max-width: auto;
                            cursor: pointer;
                        }
                    }
                    &_textbox {
                        .item_file_item_title {
                            width: 10.5rem;
                            font-size: 0.6rem;
                            font-weight: bold;
                            color: #333333;
                            line-height: normal;
                            margin-bottom: 0.15rem;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            word-wrap: break-word;
                            word-break: break-all;
                            white-space: nowrap;
                        }
                        .item_file_item_des {
                            font-size: 0.5rem;
                            color: #999999;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
    #modifyPersInfo {
        display: none;
        background-color: #f1f0f6;
        font-family: 微软雅黑;
        background: #f7f7f7;
        .profileContent {
            color: #333;
            padding: 0 0.5rem 0.5px 0.5rem;
            margin-top: 1.1rem;
        }
        .g_globalLine {
            margin: 0;
            border-bottom: solid #f0f0f0 1px;
            position: relative;
            width: 100%;
        }
        .g_input {
            padding-left: 3.75rem;
        }
        .canNotReset {
            color: #d8d8d8;
        }
        .mobileCtVal {
            position: absolute;
            width: 6.8rem;
            height: 2.5rem;
            line-height: 2.5rem;
            background: #fff;
            text-indent: 3.35rem;
            font-size: 0.6rem;
        }
        .mobileCtBorder {
            left: 6.35rem;
            position: absolute;
            border-left: 1px solid #cccccc;
            height: 0.7rem;
            top: 0.78rem;
        }
        #mobileCt {
            opacity: 0;
            width: 6.8rem;
            padding-left: 2rem;
        }
        #mobile {
            width: 8.2rem;
            padding-left: 0.15rem;
            padding-right: unset;
            vertical-align: bottom;
        }
        .profileContent .sendIcon {
            color: #f7f7f7;
        }
    }
    .loginErrorLine {
        display: none;
        position: fixed;
        text-align: center;
        top: 50%;
        width: 200px;
        margin-left: 3rem;
        .errorTips {
            background: rgba(0, 0, 0, 0.7);
            border-radius: 4px;
            color: #fff;
            font-size: 0.7rem;
            padding: 0.7rem;
            word-wrap: break-word;
        }
    }
    .viewMyReward,
    .modifyProfile {
        display: none;
        background: #f7f7f7;
    }
    #modifyPsw {
        .profileContent {
            color: #333;
        }
        .g_input {
            padding-left: 3.55rem;
        }
        .profileSubmit:disabled {
            opacity: 0.5;
        }
    }
    #viewMyReward {
        background: #fff;
        /* 会员中心-我的奖品 */
        .view-reward-content .reward-list {
            background: #fff;
        }
        .view-reward-content .reward-list .empty-wrap {
            position: relative;
            top: 6rem;
        }
        .view-reward-content .reward-list .empty-wrap .empty-list {
            height: 7.13rem;
            width: 12.31rem;
            margin: 0 auto 0.5rem auto;
            background: url(/image/v2/voidpro.png?v=202011041349) no-repeat center;
            background-size: 100%;
        }
        .view-reward-content .reward-list .empty-wrap .empty-text {
            font-size: 0.81rem;
            font-weight: 500;
            color: #333333;
            line-height: 1.25rem;
            text-align: center;
        }

        .view-reward-content .reward-list .reward-item {
            box-sizing: border-box;
            margin: 0 1rem 0 0.84rem;
            padding-top: 0.6rem;
            padding-bottom: 0.6rem;
            border-bottom: 0.03rem solid #eee;
        }
        .view-reward-content .reward-list .reward-item .reward-title {
            width: 100%;
            font-size: 0.7rem;
            font-weight: 500;
            color: #333;
            line-height: 1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .view-reward-content .reward-list .reward-item .reward-date {
            font-size: 0.5rem;
            font-weight: 500;
            color: #999;
            line-height: 1rem;
        }
    }
    /*************************积分详情会员中心 start*************************/
    #modifyIntegral .profileContent {
        padding: 0 !important;
        margin: 0 !important;
        background: #f7f7f7 !important;
    }
    #modifyIntegral .whole_top {
        width: 100%;
    }
    #modifyIntegral .top_container {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        height: 6rem;
        align-items: center;
        justify-content: center;
    }
    #modifyIntegral .top_container .widget {
        font-size: 0.55rem;
        color: #fff;
        display: inline-block;
        min-width: 2.176rem;
        max-width: 5.333rem;
        height: 1.067rem;
        line-height: 1.067rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    #modifyIntegral .top_container .itgRuleForward {
        width: 3rem;
        height: 1rem;
        border: 0.05rem solid rgba(255, 255, 255, 1);
        border-radius: 0.55rem;
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        font-size: 0.5rem;
        color: #fff;
        line-height: 0.94rem;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }
    #modifyIntegral .top_container .credit_points {
        font-size: 1.15rem;
    }
    #modifyIntegral .nav_container {
        width: 100%;
        height: 2.55rem;
        position: relative;
        background: #fff;
        margin-top: 0.3rem;
        z-index: 999;
    }
    #modifyIntegral .nav_container .nav {
        width: 100%;
        height: 2.25rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-bottom: 1px solid #eee;
    }
    #modifyIntegral .nav_container .nav .item {
        font-size: 0.7rem;
        color: #999;
    }
    #modifyIntegral .nav_container .nav .item.checked {
        color: #333;
        font-weight: bold;
    }
    #modifyIntegral .nav_container .underline {
        display: inline-block;
        position: absolute;
        width: 1.05rem;
        height: 0.125rem;
        border-radius: 0.0625rem;
        top: 2.15rem;
        transition: left 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.325);
    }
    #modifyIntegral .list {
        width: 100%;
    }
    #modifyIntegral .a_itg {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /*height: 2.5rem;*/
        border-bottom: 1px solid #eee;
        background: #fff;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
    }
    #modifyIntegral .loadMore {
        display: flex;
        height: 2.5rem;
        align-items: center;
        justify-content: center;
        font-size: 0.5973rem;
        color: #353535;
        cursor: pointer;
    }
    #modifyIntegral .a_itg_order {
        height: 3rem;
    }
    #modifyIntegral .a_itg .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 0.75rem;
    }
    #modifyIntegral .a_itg .left .top {
        font-size: 0.6rem;
        color: #333;
    }
    #modifyIntegral .a_itg .left .middle {
        font-size: 0.5rem;
        color: #333;
    }
    #modifyIntegral .a_itg .left .bottom {
        margin-top: 0.256rem;
        font-size: 0.45rem;
        color: #999;
    }
    #modifyIntegral .a_itg .right.income {
        font-size: 0.8rem;
        margin-right: 0.75rem;
        color: #f13a3a;
    }
    #modifyIntegral .a_itg .right.expenses {
        font-size: 0.8rem;
        margin-right: 0.75rem;
        color: #4ab344;
    }
    #modifyIntegral .no_itg {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 19.2rem;
        width: 100%;
        background: #fff;
        display: none;
    }
    #modifyIntegral .no_itg .img {
        background: url('/image/mall/wxmallapp/noItg.png') no-repeat;
        width: 10.67rem;
        height: 10.67rem;
        position: relative;
        top: -2.1333rem;
        background-size: contain;
    }
    #modifyIntegral .no_itg .txt {
        font-size: 0.75rem;
        position: relative;
        top: -3.2rem;
        color: #353535;
    }
    /*************************积分详情会员中心 end*************************/
    #modifyCollection .profileContent .noCollIcon {
        background: url(/image/v2/defaultIcon01.png?v=201711250607) no-repeat center;
        width: 5.5rem;
        height: 6rem;
        margin: 18% auto 1rem;
        background-size: 3.5rem;
    }
    #modifyCollection .profileContent .noCollTip {
        font-size: 0.6rem;
        font-family: 微软雅黑;
        color: #666;
        padding-bottom: 200px;
        text-align: center;
    }
    #modifyCollection .profileContent li {
        position: relative;
        list-style: none;
        height: 5rem;
        padding: 0.5rem 0;
        border-bottom: 1px solid #eee;
    }
    #modifyCollection .profileContent li:first-child {
        padding-top: 0rem;
        height: 4.5rem;
    }
    #modifyCollection .profileContent li .pdImg img {
        max-width: 4rem;
        max-height: 4rem;
    }
    #modifyCollection .profileContent li .pdImg {
        position: absolute;
        display: inline-block;
        margin-right: 0.5rem;
    }
    #modifyCollection .profileContent li .pdName {
        height: 2rem;
        padding-left: 4.5rem;
        vertical-align: top;
        line-height: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    #modifyCollection .profileContent li .pdPrice {
        height: 2rem;
        line-height: 2rem;
        padding-left: 4.5rem;
        position: relative;
    }
    #modifyCollection .profileContent li .mallPrice {
        vertical-align: bottom;
        line-height: 1rem;
        width: 50%;
        display: inline-block;
        color: #ff4400;
    }
    #modifyCollection .profileContent li .cancelCollection {
        max-width: 6rem;
        text-align: center;
        line-height: 1.4rem;
        padding: 0 0.6rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        position: absolute;
        border: 1px #666 solid;
        color: #666;
        border-radius: 3px;
        right: 0rem;
        bottom: 0.5rem;
        cursor: pointer;
    }

    #modifyCollection.collectionV2 {
        .profileContent {
            padding: 0.5rem 0.5rem 0.5px 0.5rem;
            font-size: 0.7rem;
            li {
                background: initial;
                box-sizing: border-box;
                .cancelCollection {
                    width: 3.6rem;
                    height: 1.2rem;
                    padding: 0;
                    border: 1px solid #dadada;
                    font-size: 0.6rem;
                    line-height: 1.2rem;
                }
            }
        }
    }

    #editMemberHeadPic {
        background-color: #000;
        width: 100%;
    }
    #editMemberHeadPic .picArea {
        border-top: 1px solid #1d1d1d;
        position: relative;
        max-width: 100%;
    }
    #editMemberHeadPic .picArea img {
        position: absolute;
    }
    #editMemberHeadPic .picArea .coverImg {
        z-index: 1;
    }
    #editMemberHeadPic .picArea .cover {
        opacity: 0.5;
        background-color: #000;
        position: absolute;
        z-index: 1;
    }
    #editMemberHeadPic .picArea .coverRing {
        position: absolute;
        border: 1px solid #fff;
        border-radius: 50%;
    }
    #editMemberHeadPic .picArea .coverBox {
        cursor: move;
        position: absolute;
        border: 1px dashed #fff;
        z-index: 99;
    }
    #editMemberHeadPic .picArea .coverBox .dragPoint {
        z-index: 5;
        position: absolute;
        width: 8px;
        height: 8px;
        overflow: hidden;
        cursor: nw-resize;
        background: white none repeat scroll 0% 0%;
    }

    #editMemberHeadPic .editHeadPicFooter {
        position: absolute;
        bottom: 0px;
        display: inline-block;
        text-align: center;
        height: 3rem;
        width: 100%;
        line-height: 3rem;
        margin: 0;
        padding: 0;
        background-color: #222;
    }
    #editMemberHeadPic .editBtn {
        position: absolute;
        display: inline-block;
        padding: 0 0.5rem;
        margin: 0.5rem 1.5rem;
        height: 2rem;
        line-height: 2rem;
        width: 4rem;
        border: 1px solid #333;
        border-radius: 5px;
        font-size: 0.9rem;
        color: #fff;
        cursor: pointer;
    }
    #editMemberHeadPic .saveBtn {
        right: 0;
    }
    #editMemberHeadPic .modifyBtn {
        left: 0;
    }
    #editMemberHeadPic .editHeadPicFooter .swfupload {
        position: absolute;
        left: 0;
        filter: alpha(opacity=0);
        -moz-opacity: 0;
        opacity: 0;
        z-index: 99;
        width: 4rem;
        height: 2rem;
    }
    .fileUploadBtn {
        position: absolute;
        opacity: 0;
        left: 0;
        top: 0;
        width: 4rem;
        height: 2rem;
    }
    .checkEmail {
        text-align: left;
        padding: 0 0.5rem;
        width: 93%;
        word-wrap: break-word;
    }
    .emailAddr {
        display: none;
        margin-top: 0.5rem;
        text-align: left;
        padding-left: 0.5rem;
        color: #0066cc;
    }
    .profileContent li .clickIcon .icon-clickIcon:before {
        content: '\e672';
        font-size: 0.9rem;
    }

    .jz_oldTheme {
        #modifyPersInfo {
            background: #f1f0f6;
            .g_globalLine {
                margin-top: 0.3rem;
            }
            .canNotReset {
                background: #f9f9f9;
            }
            input {
                border: none;
            }
            #mobile {
                border-left: 1px solid #ebebeb;
            }
            .mobileLine {
                display: flex;
                align-items: center;
            }
            .g_button {
                height: 2.5rem;
            }
        }
    }
}
.g_locale2052 .icon-userIcon:before {
    content: '账号';
}
.g_locale2052 .icon-fastUserIcon:before {
    content: '手机号';
}
.g_locale2052 .icon-acctIcon:before {
    content: '账号名';
}
.g_locale2052 .icon-pswIcon:before {
    content: '密码';
}
.g_locale2052 .icon-fastCodeIcon:before {
    content: '验证码';
}
.g_locale2052 .icon-emailIcon:before {
    content: '邮箱';
}
.g_locale2052 .icon-phoneIcon:before {
    content: '电话';
}
.g_locale2052 .icon-msgIcon:before {
    content: '留言';
}
.g_locale2052 .icon-nameIcon:before {
    content: '姓名';
}
.g_locale2052 .fk-findPwByMobile .icon-codeIcon:before {
    content: '验证码';
}
.g_locale2052 .icon-codeIcon:before {
    content: '密保码';
}
.g_locale2052 .icon-npwdIcon:before {
    content: '新密码';
}
.g_locale2052 .customIcon {
    display: inline-block;
    top: 0.8rem;
    left: 0.75rem;
}
.g_locale2052 .icon-customIcon:before {
    display: none;
}
.g_locale2052 .icon-iphone2:before {
    display: none;
}
.g_locale2052 .g_button {
    background-position: 32% 40%;
}
.g_locale2052 .customIcon {
    display: inline-block;
    top: 0.8rem;
    left: 0.75rem;
}
.g_locale2052 #modifyPsw .icon-pswIcon:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}
.icon-pnameIcon {
    border-radius: 50%;
    border: 3px solid #e6e6e6;
    display: inline-block;
    z-index: 1;
    overflow: hidden;
}
</style>

<style lang="scss" scoped>
@import './common.scss';
</style>
