<template>
    <div class="product_audio_player_wrapper" :class="productAudioPlayerWrapperClass">
        <div style="position: relative; display: inline-block">
            <component
                :is="imgTag"
                class="product_audio_player_banner proDetailImg J_img_lazyload"
                :src-original="picPath"
                :class="imgZoomClass"
            />

            <div v-if="isShowTryTipsBtn" class="product_audio_try_tips">{{ tryTips }}</div>
            <div v-if="isShowBuyTips" class="product_audio_try_tips">{{ LS.listenAfterPurchase }}</div>
        </div>
        <div class="product_audio_player">
            <audio ref="audio" @canplay="oncanplay" @timeupdate="audioTimeUpdate" @ended="audioEnd"></audio>
            <div class="product_audio_player_progress_wrapper" :style="progressWrapperStyle">
                <div
                    ref="progressLine"
                    class="product_auido_player_progress_base_line"
                    @[downEvent]="controlAudioProgress"
                >
                    <div
                        class="product_audio_player_progress_checked g_oldThemeBgColor g_main_bgColor_v3"
                        :style="checkLineStyle"
                    ></div>
                    <div
                        class="product_audio_player_progress_hanlde g_oldThemeBgColor g_main_bgColor_v3"
                        :style="handleStyle"
                        @[downEvent]="moveControlAudioProgress"
                    ></div>
                </div>
                <div class="product_audio_player_time_area">
                    <div class="product_audio_player_current_time product_audio_player_time">{{
                        currentTime | transTime
                    }}</div>
                    <div class="product_audio_player_all_time product_audio_player_time">{{
                        duration | transTime
                    }}</div>
                </div>
            </div>
            <div class="product_audio_player_tool">
                <svg class="product_audio_player_float" :style="floatStyle" @click="showWXfloatTips">
                    <use xlink:href="#jzm-vicon_switch"></use>
                </svg>
                <svg class="product_audio_player_retreat" @click="retreatHandler">
                    <use xlink:href="#jzm-vicon_retreat"></use>
                </svg>
                <svg
                    class="product_audio_player_switch g_main_color_v3 g_oldThemeColor"
                    :style="switchStyle"
                    @click="switchStatus"
                >
                    <use :xlink:href="playStatusIcon"></use>
                </svg>
                <svg class="product_audio_player_forward" @click="forwardHandler">
                    <use xlink:href="#jzm-vicon_forward"></use>
                </svg>
                <svg class="product_audio_player_multiple" @click="switchSpeedHandler">
                    <use :xlink:href="multipleIcon"></use>
                </svg>
            </div>
            <div v-show="isShowTryTips" v-dom-portal="target" class="product_audio_player_wx_tips">
                <span class="product_audio_player_wx_tips_text">
                    <span>{{ LS.wxRightTopTips }}</span>
                    <svg class="product_audio_player_floatingwindow">
                        <use xlink:href="#jzm-vicon_floatingwindow"></use>
                    </svg>
                    <span>{{ LS.listenAndWxChat }}</span>
                </span>
                <div class="product_audio_player_wx_tips_hide_btn" @click="hideHanlder">{{ LS.iknow }}</div>
                <svg class="product_audio_player_wx_tips_tri">
                    <use xlink:href="#jzm-vicon_triangle"></use>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import { productKnowPayBtnClick } from '@/modules/shared/product/knowPay/btn';
import { format } from '@/shared/fai';

let downEvent = null;
let moveEvent = null;
let upEvent = null;
let isWechat = null;
let supportsTouch = null;
if (typeof window !== 'undefined') {
    supportsTouch =
        (window.DocumentTouch && document instanceof window.DocumentTouch) ||
        'ontouchstart' in document.documentElement;
    downEvent = supportsTouch ? 'touchstart' : 'mousedown';
    moveEvent = supportsTouch ? 'touchmove' : 'mousemove';
    upEvent = supportsTouch ? 'touchend' : 'mouseup';
    let userAgent = navigator.userAgent.toLowerCase();
    isWechat = userAgent.match(/MicroMessenger/i) == 'micromessenger';
}

import { ing } from '@/shared/tips';
import { addRequestPrefix } from '@/shared/util';
import { mapState } from 'vuex';

export default {
    filters: {
        transTime(value) {
            function padZero(value) {
                let str = `${value}`.padStart(2, '0');
                return str;
            }
            //  value是秒
            let m = padZero(parseInt(value / 60)); // 分
            let s = padZero(parseInt(value % 60)); // 秒
            return `${m}:${s}`;
        },
    },
    props: [
        // eslint-disable-next-line vue/require-prop-types
        'picPath',
        // eslint-disable-next-line vue/require-prop-types
        'istryOpen',
        // eslint-disable-next-line vue/require-prop-types
        'isFinPay',
        // eslint-disable-next-line vue/require-prop-types
        // eslint-disable-next-line vue/require-prop-types
        'audioTryMin',
        // eslint-disable-next-line vue/require-prop-types
        'imgZoomClass',
        // eslint-disable-next-line vue/require-prop-types
        'isV1',
        // eslint-disable-next-line vue/require-prop-types
        'isDefaultColorStyle',
        // eslint-disable-next-line vue/require-prop-types
        'followColor',
        // eslint-disable-next-line vue/require-prop-types
        'isUseCustomColor',
        // eslint-disable-next-line vue/require-prop-types
        'productId',
    ],
    data() {
        return {
            progressWidth: '14',
            hanldeRadius: 0.225,
            isPlaying: false,
            duration: 0,
            currentTime: 0,
            moveEvent,
            downEvent,
            upEvent,
            target: 'body',
            isShowTryTips: false,
            multipleArray: [0.5, 1, 1.25, 1.5, 2],
            multipleCount: 1,
            isInitAudio: false,
            isWechat,
        };
    },
    computed: {
        ...mapState('app', ['LS']),
        tryTips() {
            return format(this.LS.auditionMinutes1, this.audioTryMin);
        },
        productAudioPlayerWrapperClass() {
            return 'product_audio_player_normal_header';
        },
        checkLineWidth() {
            return this.progressWidth * (this.progress / 100);
        },
        checkLineStyle() {
            let style = {
                width: `${this.checkLineWidth}rem`,
            };
            if (this.isUseCustomColor && !this.isDefaultColorStyle) {
                style['background-color'] = this.followColor;
            }
            return style;
        },
        handleStyle() {
            let style = {
                left: `${this.checkLineWidth - this.hanldeRadius}rem`,
            };
            if (this.isUseCustomColor && !this.isDefaultColorStyle) {
                style['background-color'] = this.followColor;
            }
            return style;
        },
        progressWrapperStyle() {
            return {
                width: `${this.progressWidth}rem`,
            };
        },
        playStatusIcon() {
            return this.isPlaying ? '#jzm-vicon_suspend' : '#jzm-vicon_play';
        },
        progress() {
            if (!this.duration) {
                return 0;
            }
            return (this.currentTime / this.duration) * 100;
        },
        isShowTryTipsBtn() {
            return this.istryOpen && !this.isFinPay;
        },
        isShowBuyTips() {
            return !this.istryOpen && !this.isFinPay;
        },
        floatStyle() {
            let style = {};
            if (!this.isWechat) {
                style.visibility = 'hidden';
            }
            return style;
        },
        currentMultiple() {
            return this.multipleArray[this.multipleCount % this.multipleArray.length];
        },
        multipleIcon() {
            let str = `${this.currentMultiple}`;
            return `#jzm-vicon_${str}x`;
        },
        imgTag() {
            if (this.isV1) {
                return 'img';
            } else {
                return 'div';
            }
        },
        // eslint-disable-next-line vue/return-in-computed-property
        switchStyle() {
            if (this.isUseCustomColor && !this.isDefaultColorStyle) {
                return {
                    color: this.followColor,
                };
            }
        },
    },
    mounted() {},
    methods: {
        switchStatus() {
            let audio = this.$refs.audio;
            if (audio.paused) {
                this.checkAudio();
            } else {
                audio.pause();
                this.isPlaying = false;
            }
        },
        checkAudio() {
            if (VITE_APP_MODE !== 'visitor') {
                if (this.istryOpen) {
                    ing('当前为管理态，不支持试听');
                } else {
                    ing('当前为管理态，不支持购买');
                }
            } else if (!this.isInitAudio) {
                jm.ajax({
                    type: 'post',
                    url:
                        addRequestPrefix({
                            newPath: '/ajax',
                            oldPath: 'ajax',
                        }) + '/product_h.jsp?cmd=getWafNotCk_getKnowProductPayStatus',
                    data: 'productId=' + this.productId,
                    success: (res) => {
                        let result = jm.parseJSON(res);
                        if (result.success) {
                            this.initPlayAduio(result);
                        } else {
                            ing(this.LS.listenAfterPurchase);
                        }
                    },
                    error() {},
                });
            }
            if (this.isInitAudio) {
                this.playAudio();
            }
        },
        initPlayAduio({ isConvertToM3u8Success, useM3u8, mediaPath }) {
            let audio = this.$refs.audio;
            if (!isConvertToM3u8Success) {
                ing(this.LS.audioFormatError);
                return;
            }
            if (!useM3u8) {
                ing(this.LS.videoEncryption);
                return;
            }

            if (Hls.isSupported()) {
                let hls = new Hls();
                hls.loadSource(mediaPath);
                hls.attachMedia(audio);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    this.isInitAudio = true;
                    this.playAudio();
                });
            } else if (audio.canPlayType('application/vnd.apple.mpegurl')) {
                audio.src = mediaPath;
                this.isInitAudio = true;
                jm(audio)
                    .unbind('loadedmetadata.knowPayVideo')
                    .bind('loadedmetadata.knowPayVideo', () => {
                        this.playAudio();
                    });
            }
        },
        playAudio() {
            let audio = this.$refs.audio;
            // 暂停所有audio
            jm('audio').each((_, el) => {
                el.pause();
            });
            audio
                .play()
                .then(() => {
                    let currentTime = audio.currentTime;
                    if (currentTime === audio.duration) {
                        audio.currentTime = 0;
                    }

                    this.isPlaying = true;
                })
                .catch(() => {
                    this.isPlaying = false;
                });
        },
        oncanplay() {
            this.updateDuration();
        },
        audioTimeUpdate() {
            this.updateDuration();
            this.currentTime = this.$refs.audio.currentTime;
        },
        controlAudioProgress(event) {
            this.updateAudioProgress(event);

            jm(this.$el)
                .off(`${this.moveEvent}.audio`)
                .on(`${this.moveEvent}.audio`, this.updateAudioProgress.bind(this));

            jm(this.$el)
                .off(`${this.upEvent}.audio`)
                .on(`${this.upEvent}.audio`, () => {
                    jm(this.$el).off(`${this.moveEvent}.audio`).off(`${this.upEvent}.audio`);
                });
        },
        updateAudioProgress(event) {
            let audio = this.$refs.audio;

            let progressLine = this.$refs.progressLine;
            if (!audio.paused || audio.currentTime != 0) {
                let pgsWidth = parseFloat(window.getComputedStyle(progressLine, null).width.replace('px', ''));
                let currentProgressWidth = 0;
                let progressLineOffsetLeft = jm(progressLine).offset().left;
                if (supportsTouch) {
                    currentProgressWidth = event.touches[0].pageX - progressLineOffsetLeft;
                } else {
                    currentProgressWidth = event.pageX - progressLineOffsetLeft;
                }
                let rate = currentProgressWidth / pgsWidth;

                audio.currentTime = audio.duration * rate;
                this.audioTimeUpdate();
            }
        },
        retreatHandler() {
            let retreatTime = this.currentTime - 15;
            retreatTime = retreatTime < 0 ? 0 : retreatTime;
            this.currentTime = retreatTime;
            this.$refs.audio.currentTime = retreatTime;
        },
        forwardHandler() {
            let forwardTime = this.currentTime + 15;
            forwardTime = forwardTime > this.duration ? this.duration : forwardTime;
            this.currentTime = forwardTime;
            this.$refs.audio.currentTime = forwardTime;
        },
        showWXfloatTips() {
            this.isShowTryTips = true;
        },
        hideHanlder() {
            this.isShowTryTips = false;
        },
        switchSpeedHandler() {
            this.multipleCount += 1;
            let multiple = this.currentMultiple;
            let audio = this.$refs.audio;
            audio.playbackRate = multiple;
        },
        audioEnd() {
            if (this.istryOpen && !this.isFinPay) {
                this.createKnowPayPopup();
            }
            this.isPlaying = false;
        },
        moveControlAudioProgress() {},
        updateDuration() {
            let duration = this.$refs.audio.duration;
            if (Number.isNaN(duration) || duration === Infinity) {
                duration = 0;
            }
            this.duration = duration;
        },
        createKnowPayPopup() {
            let template = `
                        <div id="knowPayPopupContainer" class="knowPayPopupContainer audioKnowPay">
                            <div class="knowPayPopupBg"></div>
                            <div class="knowPayPopupContent">
                                <div class="knowPayPopupTip">
                                    <div class="knowPayPopupTitle">${this.LS.endOfAudition}</div>
                                    <div class="knowPayPopupTips">${
                                        this.LS.endOfAudition1 + this.LS.buySeparately
                                    }</div>
                                </div>
                                <div class="knowPayPopupBtnGroup">
                                    <div class="knowPayPopupCancel">${this.LS.cancel}</div>
                                    <div class="knowPayPopupClip"></div>
                                    <div class="knowPayPopupConfirm">${this.LS.productDetailBuy}</div>
                                </div>
                            </div>
                        </div>
                    `;
            if (jm('#knowPayPopupContainer').length) {
                jm('#knowPayPopupContainer').remove();
            }
            jm('body').append(template);
            jm('body').css('overflow', 'hidden'); // 阻止滚动穿透
            jm('.knowPayPopupCancel')
                .unbind('click.knowPayPopup')
                .bind('click.knowPayPopup', function () {
                    jm('.knowPayPopupConfirm').unbind('click.knowPayPopupConfirm');
                    jm('.knowPayPopupCancel').unbind('click.knowPayPopup');
                    jm('.knowPayPopupBg').unbind('touchmove.knowPayPopup');
                    jm('.knowPayPopupContent').unbind('touchmove.knowPayPopup');
                    jm('.knowPayPopupContainer').unbind('touchmove.knowPayPopup');
                    jm('#knowPayPopupContainer').remove();
                    jm('body').css('overflow', 'initial');
                    return false;
                });
            jm('.knowPayPopupConfirm')
                .unbind('click.knowPayPopupConfirm')
                .bind('click.knowPayPopupConfirm', function () {
                    if (this.productId != null) {
                        productKnowPayBtnClick(this.productId);
                    }
                    return false;
                });
            jm('.knowPayPopupBg')
                .unbind('touchmove.knowPayPopup')
                .bind('touchmove.knowPayPopup', function (e) {
                    e.preventDefault();
                });
            jm('.knowPayPopupContent')
                .unbind('touchmove.knowPayPopup')
                .bind('touchmove.knowPayPopup', function (e) {
                    e.preventDefault();
                });
            jm('.knowPayPopupContainer')
                .unbind('touchmove.knowPayPopup')
                .bind('touchmove.knowPayPopup', function (e) {
                    e.preventDefault();
                });
        },
    },
};
</script>
