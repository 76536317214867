<template>
    <module-frame :module-id="moduleId">
        <template v-if="isEmptyProductInfo || (notAdded && !_manageMode)">
            <div class="productNotAdded productNotAdded-icon"></div>
            <div class="productNotAdded-tip">{{ productNotAdded }}</div>
        </template>
        <template v-else>
            <div :id="proDetailPanelId" class="productDetail_v1">
                <div :id="proDetailDivId" class="proDetailDiv">
                    <div id="productImageSwipe" class="proImgSwipe">
                        <template v-if="openKnowPayment">
                            <div v-if="isVideoPayProduct" class="knowPayProduct">
                                <div
                                    class="proDetailImg J_img_lazyload"
                                    :style="proDetailImgStyle"
                                    :src-original="picPath"
                                >
                                    <template v-if="isFinPay">
                                        <div class="videoPlay faisco-icons-" @click="checkKnowPayVideoHandler"></div>
                                        <div id="knowproductVideoMask" style="display: none">
                                            <div class="mobiProductVideoDialog productKnowPayVideo">
                                                <video
                                                    id="knowPayVideo"
                                                    :style="knowPayVideoStyle"
                                                    class="mobiVideo"
                                                    controls
                                                    webkit-playsinline="true"
                                                    playsinline="true"
                                                    controlsList="nodownload"
                                                    preload="auto"
                                                ></video>
                                            </div>
                                            <div class="videoQuit" @click="productKnowPayVideoQuit">
                                                <svg class="videoQuit-close">
                                                    <use href="#jzm-vicon_close"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <template v-if="finalOpener">
                                            <div
                                                class="videoPlay faisco-icons-"
                                                @click="checkKnowPayVideoHandler(productId, finalOpener)"
                                            ></div>
                                            <div class="videoTryTipContainer">
                                                <div class="videoTryBg"></div>
                                                <div class="videoTryTip">
                                                    <span>{{ tryTips }}</span>
                                                </div>
                                            </div>
                                            <div id="knowproductVideoMask" style="display: none">
                                                <div class="mobiProductVideoDialog productKnowPayVideo">
                                                    <video
                                                        id="knowPayVideo"
                                                        :style="knowPayVideoStyle"
                                                        class="mobiVideo"
                                                        controls
                                                        webkit-playsinline="true"
                                                        playsinline="true"
                                                        controlsList="nodownload"
                                                        preload="auto"
                                                    ></video>
                                                </div>
                                                <div class="videoQuit" @click="productKnowPayVideoQuit">
                                                    <svg class="videoQuit-close">
                                                        <use href="#jzm-vicon_close"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div
                                                class="videoPlay faisco-icons-"
                                                @click="checkKnowPayVideoHandler(productId)"
                                            ></div>
                                            <div id="knowproductVideoMask" style="display: none">
                                                <div class="mobiProductVideoDialog productKnowPayVideo"></div>
                                                <div class="videoQuit" @click="productKnowPayVideoQuit">
                                                    <svg class="videoQuit-close">
                                                        <use href="#jzm-vicon_close"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </div>
                            <div v-else-if="isAudioProduct" id="productAudioPlayer">
                                <product-audio-player
                                    :pic-path="audioPicPath"
                                    :istry-open="istryOpen"
                                    :is-v1="isV1"
                                    :is-default-color-style="isDefaultColorStyle"
                                    :follow-color="followColor"
                                    :is-use-custom-color="isUseCustomColor"
                                    :is-fin-pay="isFinPay"
                                    :audio-try-min="audioTryMin"
                                    :img-zoom-class="imgZoomClass"
                                    :product-id="productId"
                                ></product-audio-player>
                            </div>
                        </template>
                        <div v-if="!isVideoPayProduct && !isAudioProduct" class="proDetailImgBox">
                            <template v-if="!isEmptyPicPathList">
                                <div v-for="pic in picPathList" class="swipImgBox">
                                    <span class="imageMiddleSpan"></span>
                                    <img
                                        class="J_img_lazyload"
                                        :vwidth="pic.width"
                                        :vheight="pic.height"
                                        :src="loadingPath"
                                        :src-original="pic.newPath"
                                    />
                                </div>
                            </template>
                            <!-- 无图 -->
                            <div v-else>
                                <img class="proDetailImg" :src="defaultPath" />
                            </div>
                        </div>
                        <!-- 多张图的轮播小圆点 -->
                        <div v-if="picPathList.length > 1 && !openKnowPayment" id="productBullet" class="productBullet">
                            <ul id="bullets" class="bullets">
                                <li v-for="(item, index) in bullets" v-if="index < 5" :class="{ on: index === 1 }"></li>
                            </ul>
                        </div>
                    </div>
                    <!-- 产品参数 -->
                    <div class="paramCollection">
                        <h1 class="g_text paramName" v-html="name"></h1>
                        <template v-for="propInfo in showPropList">
                            <p v-if="propInfo.value" class="g_text productParam">
                                <span v-if="showPar">{{ propInfo.name }}：</span>
                                <span v-if="propInfo.type === 11" class="mallPrice"
                                    >{{ choiceCurrencyVal }}<span id="mallPrice">{{ propInfo.value }}</span>
                                </span>

                                <span v-else-if="propInfo.type === 12" class="marketPrice"
                                    >{{ choiceCurrencyVal }}{{ propInfo.value }}</span
                                >
                                <span v-else class="propVal" v-html="propInfo.value"></span>
                            </p>
                        </template>
                    </div>

                    <!-- 购买选项 -->

                    <!-- TODO,分离html -->
                    <div
                        v-if="isJZShoppingOptionOpen && showOptionFlag"
                        class="mallOptionBox J-op-box"
                        v-html="showOptionHtml"
                    >
                    </div>

                    <!-- 产品评论 -->
                    <div v-if="realNameAuth.allowShowMessage" class="pdCommentSwitchBox">
                        <div v-if="!showDetail" class="pdCommentSwitch" @click="pdCommentSwitchClickHandler">
                            <div class="left shuomingIcon"></div>
                            <div class="middle">{{ productCommentDetailDesc }}</div>
                            <div class="right rightArrow"></div>
                        </div>
                        <div
                            v-if="isOpen"
                            class="pdCommentSwitch"
                            :style="styleBorderTop"
                            @click="pdCommentSwitchClickHandler2"
                        >
                            <div class="left liuyanIcon"></div>
                            <div class="middle">{{ productCumulativeComments }}{{ totalSize }}</div>
                            <div class="right rightArrow"></div>
                        </div>
                    </div>

                    <div
                        v-if="bookingOpen || immediatelyConsultOpen || productFormOpen || openKnowPayment"
                        :class="{
                            btnGroup: true,
                            half: (bookingOpen || productFormOpen || openKnowPayment) && immediatelyConsultOpen,
                        }"
                    >
                        <input
                            v-if="openKnowPayment"
                            id="productBooking"
                            :_pid="productId"
                            type="button"
                            class="g_button"
                            :value="knowPaymentText"
                            :style="productBookingStyle"
                            @click="productBookingClickHandler"
                        />
                        <template v-else>
                            <a
                                v-if="immediatelyConsultOpen"
                                id="consultImmediatelyBtn"
                                :_pid="productId"
                                type="button"
                                class="g_button g_conSultBtn"
                                >{{ advisoryTxt }}</a
                            >
                            <input
                                v-if="bookingOpen || productFormOpen"
                                id="productBooking"
                                :_pid="productId"
                                type="button"
                                class="g_button"
                                :value="shopBtnTypeText"
                                @click="productBookingClickHandler"
                            />
                        </template>
                    </div>
                </div>
                <template v-if="list.length >= 1 && pageIcon">
                    <div id="prevAndNextDiv">
                        <table class="paginateTable">
                            <tbody>
                                <tr>
                                    <td colspan="2" align="center" valign="middle">
                                        <!-- TODO: 分离html -->
                                        <div
                                            class="pagenation"
                                            :class="{
                                                disable: isDisablePagenation,
                                            }"
                                            v-html="nextPage + lastPage"
                                        >
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
                <html-render v-if="showDetail" class="richContent remarkBox" :html="remark"></html-render>
                <div v-if="isPicTextProduct && !isFinPay" :style="picTextProductNotPayWarpStyle">
                    <div class="knowPayNotPayTips"></div>
                    <span :style="knowPayNotPayTextStyle">{{ LS.viewAllAfterPurchase }}</span>
                </div>
            </div>
        </template>
    </module-frame>
</template>

<script>
import { showTopTips } from '@/shared/tips';

import { initModulePhotoSwipe } from '@/modules/shared/photo';
import { initProductOptions } from '@/modules/shared/product/productOptions';
import { consultImmediatelyBtnClick } from '@/modules/shared/product/consultImmediate';
import { bookingPanelClickEvent, initProductEvent, initRepPropValueOfURL } from '@/modules/shared/product/product';
import { switchJump } from '@/shared/linkSetting';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { setHrefEventHasReqArgs, createFullScreenDiv } from '@/modules/comm';
import { checkKnowPayVideo, productKnowPayVideoQuit } from '@/modules/shared/product/knowPay/video';
import { productKnowPayBtnClick } from '@/modules/shared/product/knowPay/btn';
import { getCommentDiv } from '@/modules/shared/comment';
import { mapGetters, mapState, mapMutations } from 'vuex';
import ModuleFrame from '@/modules/frame/index.vue';
import { productFormBtnClick } from '@/modules/shared/product/btn';
import productAudioPlayer from '../../components/productAudioPlayer.vue';
import { getRouterData } from '@/features/router/universal';

import HtmlRender from '@/components/html-render/index.vue';
import { MOBILE_AUTH_TIPS } from '@/services';
import { format } from '@/shared/fai';

export default {
    components: {
        ModuleFrame,
        productAudioPlayer,
        HtmlRender,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            isDisablePagenation: true,
        };
    },
    computed: {
        ...mapState('app', ['LS', 'realNameAuth']),
        ...mapState(['mobiTipList']),
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        proDetailPanelId() {
            return `proDetailPanel${this.moduleId}`;
        },
        proDetailDivId() {
            return `proDetailDiv${this.moduleId}`;
        },
        tryTips() {
            return format(this.LS.trialMinutes, this.videoTryMin);
        },
        picPathList() {
            // TODO，这里应该在后台裁剪
            return this.extInfo.picPathList.slice(0, 5);
        },
        isEmptyPicPathList() {
            return this.picPathList.length === 0;
        },
        defaultPath() {
            return this.productInfo.picPath;
        },
        bullets() {
            return this.picPathList.length < 5 ? this.picPathList.length : 5;
        },
        saleTpye() {
            return this.salePromotionParam.saleType;
        },
        styleBorderTop() {
            return !this.showDetail ? 'border-top: none;' : '';
        },
        productBookingStyle() {
            return this.isFinPay ? 'opacity: 0.5;' : '';
        },
        extInfo() {
            return this.module.extInfo;
        },
        notAdded() {
            return this.extInfo.notAdded;
        },
        _manageMode() {
            return VITE_APP_MODE !== 'visitor';
        },
        isEmptyProductInfo() {
            return this.isEmpty(this.extInfo.productInfo) || this.productInfo.id <= 0;
        },
        productNotAdded() {
            return this.extInfo.productNotAdded;
        },
        isVideoPayProduct() {
            return this.extInfo.isVideoPayProduct;
        },
        isAudioProduct() {
            return this.extInfo.isAudioProduct;
        },
        loadingPath() {
            return this.extInfo.loadingPath;
        },
        name() {
            return this.extInfo.name;
        },
        value() {
            return this.extInfo.value;
        },
        showPar() {
            return this.extInfo.showPar;
        },
        isJZShoppingOptionOpen() {
            return this.extInfo.isJZShoppingOptionOpen;
        },
        showOptionFlag() {
            return this.extInfo.showOptionFlag;
        },
        showOptionHtml() {
            return this.extInfo.showOptionHtml;
        },
        showDetail() {
            return this.extInfo.showDetail;
        },
        productCommentDetailDesc() {
            return this.LS.productCommentDetailDesc;
        },
        isOpen() {
            return this.extInfo.isOpen;
        },
        productCumulativeComments() {
            return this.LS.productCumulativeComments;
        },
        bookingOpen() {
            return this.extInfo.bookingOpen;
        },
        immediatelyConsultOpen() {
            return this.extInfo.immediatelyConsultOpen;
        },
        productFormOpen() {
            return this.extInfo.productFormOpen;
        },
        openKnowPayment() {
            return this.extInfo.openKnowPayment;
        },
        knowPaymentText() {
            return this.extInfo.knowPaymentText;
        },
        isFinPay() {
            return this.extInfo.isFinPay;
        },
        advisoryTxt() {
            return this.extInfo.advisoryTxt;
        },
        shopBtnTypeText() {
            return this.extInfo.shopBtnTypeText;
        },
        remark() {
            return this.extInfo.remark;
        },
        isPicTextProduct() {
            return this.extInfo.isPicTextProduct;
        },
        list() {
            return this.extInfo.list;
        },
        pageIcon() {
            return this.extInfo.pageIcon;
        },
        lastPage() {
            return this.extInfo.lastPage;
        },
        nextPage() {
            return this.extInfo.nextPage;
        },
        choiceCurrencyVal() {
            return this.extInfo.choiceCurrencyVal;
        },
        totalSize() {
            return this.extInfo.totalSize;
        },
        showPropList() {
            return this.extInfo.showPropList;
        },
        productId() {
            return this.extInfo.productId;
        },
        productInfo() {
            return this.extInfo.productInfo;
        },
        viewAllow() {
            return this.extInfo.viewAllow;
        },
        hasThirdCode() {
            return this.extInfo.hasThirdCode;
        },
        oem() {
            return this.extInfo.oem;
        },
        bssKnowProductType() {
            return this.extInfo.bssKnowProductType;
        },
        productAudioPlayerOption() {
            return this.extInfo.productAudioPlayerOption;
        },
        isBanCopy() {
            return this.extInfo.isBanCopy;
        },
        optionsInfo() {
            return this.extInfo.optionsInfo;
        },
        usedAdvisoryMethod() {
            return this.extInfo.usedAdvisoryMethod;
        },
        advisoryPhone() {
            return this.extInfo.advisoryPhone;
        },
        wechatQrCode() {
            return this.extInfo.wechatQrCode;
        },
        mobiAdvisoryIde() {
            return this.extInfo.mobiAdvisoryIde;
        },
        proDetailImgStyle() {
            return {
                height: `${Math.ceil(16 * ((1 * 1.0) / 1))}rem`,
                [`background-image`]: `url(${this.loadingPath})`,
            };
        },
        videoTryMin() {
            return this.extInfo.videoTryMin;
        },
        finalOpener() {
            return this.extInfo.finalOpener;
        },
        picPath() {
            return this.extInfo.picPath;
        },
        isUseCustomColor() {
            return this.productAudioPlayerOption.isUseCustomColor;
        },
        followColor() {
            return this.productAudioPlayerOption.followColor;
        },
        isDefaultColorStyle() {
            return this.productAudioPlayerOption.isDefaultColorStyle;
        },
        isV1() {
            return this.productAudioPlayerOption.isV1;
        },
        istryOpen() {
            return this.productAudioPlayerOption.istryOpen;
        },
        audioTryMin() {
            return this.productAudioPlayerOption.audioTryMin;
        },
        imgZoomClass() {
            return this.productAudioPlayerOption.imgZoomClass;
        },
        audioPicPath() {
            return this.productAudioPlayerOption.picPath;
        },
        productFormId() {
            return this.extInfo.productFormId;
        },
        knowPayVideoStyle() {
            return { 'object-fit': 'scale-down', height: '100%' };
        },
        picTextProductNotPayWarpStyle() {
            return {
                display: 'flex',
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'center',
                'margin-top': '0.875rem',
                'margin-bottom': '0.5rem',
            };
        },
        knowPayNotPayTextStyle() {
            return {
                'font-size': '0.65rem',
                color: '#333',
                'margin-top': '0.5rem',
            };
        },
    },
    async mounted() {
        if (this.hasThirdCode && this.oem) {
            showTopTips();
        } else if (this.hasThirdCode && !this.oem) {
            showTopTips(3);
        }

        if (!(this.isEmptyProductInfo || (this.notAdded && !(VITE_APP_MODE !== 'visitor')))) {
            if (this.openKnowPayment) {
                Fai.top.bssKnowProductType = this.bssKnowProductType;
            }

            if (!this.isAudioProduct) {
                initModulePhotoSwipe(`proDetailPanel${this.moduleId}`);
            }

            if (this.isJZShoppingOptionOpen) {
                initProductOptions(this.optionsInfo);
            }

            if (this.bookingOpen || this.immediatelyConsultOpen || this.productFormOpen || this.openKnowPayment) {
                if (!this.openKnowPayment && this.immediatelyConsultOpen) {
                    if (this.usedAdvisoryMethod == 2) {
                        consultImmediatelyBtnClick(
                            'consultImmediatelyBtn',
                            this.usedAdvisoryMethod,
                            this.advisoryPhone,
                            this.wechatImgPath,
                            this.mobiAdvisoryIde
                        );
                    } else {
                        consultImmediatelyBtnClick(
                            'consultImmediatelyBtn',
                            this.usedAdvisoryMethod,
                            this.advisoryPhone,
                            this.wechatImgPath
                        );
                    }
                }
                if (this.bookingOpen) {
                    bookingPanelClickEvent('J_productBookingBtn', 3);
                }
            }

            if (this.showDetail) {
                switchJump();
            }
        }

        if (!this.isAudioProduct) {
            initProductEvent('productImageSwipe');
        }

        initRepPropValueOfURL();
        bindImgLazyLoad(`module${this.moduleId}`);
        setHrefEventHasReqArgs(jm(`#module${this.moduleId}`));
        // FIX: 历史问题，会把信息放到锚点，导致页面渲染后台无法得到信息处理上一页和下一页，发请求走模块初始化逻辑拿回正确上下页(访客态)
        if (!(VITE_APP_MODE !== 'visitor')) {
            const hashParams = {};

            location.hash
                .substring(1)
                .split('&')
                .forEach((item) => {
                    let tempArr = item.split('=');
                    hashParams[tempArr[0]] = tempArr[1];
                });

            const { productId: pid } = this;

            const { id: colId, extId } = this.$store.state.app.colInfo;
            try {
                const result = await jzRequest.get('/api/guest/module/getModules', {
                    params: {
                        colId,
                        extId,
                        ...getRouterData(),
                        ...hashParams,
                        pid,
                        isUpdatePageUrl: true,
                    },
                });

                const { lastPage = '', nextPage = '' } = result.data.modules[0].extInfo;

                this.extInfo.lastPage = lastPage;

                this.extInfo.nextPage = nextPage;

                this.isDisablePagenation = false;
            } catch (error) {
                console.log(error);
            }
        }

        this.initRealNameAuthTips();
    },
    methods: {
        ...mapMutations(['addMobiTips']),
        productBookingClickHandler() {
            if (this.openKnowPayment && !this.isFinPay) {
                productKnowPayBtnClick(this.productId);
                return false;
            } else if (!this.openKnowPayment && this.productFormOpen) {
                productFormBtnClick(this.productFormId, this.productId);
            }
        },
        isEmpty(val) {
            return Array.isArray(val) ? val.length === 0 : Object.keys(val).length === 0;
        },
        pdCommentSwitchClickHandler() {
            createFullScreenDiv('productRemarkDiv', 'productDetail', `id=${this.productId}`);
        },
        pdCommentSwitchClickHandler2() {
            getCommentDiv('getWafNotCk_product', this.productId);
        },
        checkKnowPayVideoHandler(productId, finalOpener) {
            checkKnowPayVideo(productId, finalOpener);
        },
        productKnowPayVideoQuit() {
            productKnowPayVideoQuit();
        },
        initRealNameAuthTips() {
            if (VITE_APP_MODE === 'visitor') {
                if (this.realNameAuth.allowShowMessage === false) {
                    this.addMobiTips(MOBILE_AUTH_TIPS);
                }
            }
        },
    },
};
</script>

<style lang="scss">
.productDetail_v1 .swipImgBox {
    font-size: 0;
}
.productDetail_v1 .paramCollection .paramName {
    font-weight: inherit;
    font-size: inherit;
}

.pagenation.disable {
    .icon-pagePrev.icon-cnListP,
    .icon-pageNext.icon-cnListN {
        background: transparent;
        &:before {
            color: #ccc !important;
        }
        a {
            pointer-events: none;
        }
    }
    .pageNext,
    .pagePrev {
        border-color: #ccc !important;
    }
}
</style>
